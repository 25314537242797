import { AppConfigTypes } from "../types/AppConfigTypes";

export const AppConfigReducer = (
  state = {
    darkMode: Boolean(),
  },
  action
) => {
  switch (action.type) {
    case AppConfigTypes.toogleDarkMode:
      return {
        darkMode: !state.darkMode,
      };

    default:
      return state;
  }
};
