import React from "react";
import { Routes, Route } from "react-router-dom";
import ProductAddPage from "../pages/Products/ProductAddPage";
import ProductsListPage from "../pages/Products/ProductsListPage";
import ProductDetailPage from "../pages/Products/ProductDetailPage";

const ProductsRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ProductsListPage />} />
      <Route exact path="/add" element={<ProductAddPage />} />
      <Route exact path="/detail" element={<ProductDetailPage />} />
    </Routes>
  );
};

export default ProductsRouter;
