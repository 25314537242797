import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { AppBar, Switch } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { signOutAction } from "../actions/AuthenticationAction";
// import { useSelector } from "react-redux";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

const lightColor = "rgba(255, 255, 255, 0.7)";

function Header(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onDrawerToggle, title } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMoreMenu, setAnchorMoreMenu] = React.useState(null);
  const open = Boolean(anchorEl);
  const openMoreMenu = Boolean(anchorMoreMenu);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMoreMenu = (event) => {
    setAnchorMoreMenu(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setAnchorMoreMenu(null);
  };

  const initials = () => {
    // console.log(name);
    // return name ? `${name[0].toUpperCase()}` : String();
  };

  // useEffect(() => {
  //   if (currentTimer == null) {
  //     var timer = setInterval(() => {
  //       setCurrentDate(Date());
  //     }, 1000);
  //     setCurrentTimer(timer);
  //   }
  // }, [currentTimer]);

  // useEffect(() => {
  //   return () => {
  //     clearInterval(currentTimer);
  //   };
  // }, []);

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            {/* MORE MENU */}
            <Grid item>
              <Tooltip title={t("more")}>
                <IconButton onClick={handleClickMoreMenu} color="inherit">
                  <MoreHorizIcon />
                </IconButton>
              </Tooltip>

              {/* <MoreMenuComponent
                open={openMoreMenu}
                anchor={anchorMoreMenu}
                handleClick={handleClickMoreMenu}
                handleClose={handleCloseMoreMenu}
              /> */}
              {/* MORE MENU */}
            </Grid>
            {/*  */}
            <Grid item>
              <IconButton onClick={handleClick} size="small">
                <Avatar sx={{ width: 32, height: 32 }}>{initials()}</Avatar>
              </IconButton>
              {/* MENU */}
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  <Avatar /> {""}
                </MenuItem>
                <MenuItem>
                  <Avatar /> My account
                </MenuItem>
                <Divider />
                <MenuItem>
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  Add another account
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <LightModeIcon fontSize="small" />
                  </ListItemIcon>
                  DARKS
                  <Switch />
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    dispatch(signOutAction());
                    navigate("/");
                  }}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
              {/* MENU */}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/* SECOND BAR */}
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {title}
              </Typography>
            </Grid>
            <Grid item>
              {/* <Button
                sx={{ borderColor: lightColor }}
                variant="outlined"
                color="inherit"
                size="small"
              >
                Web setup
              </Button> */}
              {/* <Typography>{`${currentDate.getHours()}:${currentDate.getMinutesFixed()}`}</Typography>
              <Typography>{`${currentDate.getDate()} - ${currentDate.getMonth()} - ${currentDate.getFullYear()}`}</Typography> */}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
