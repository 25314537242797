const { log } = require("./Logger");

const createCSVFile = (data) => {
  // let indexes = [];
  // data.forEach((item, index) => {
  //   if (Object.keys(item).length > Object.keys(indexes).length) {
  //     indexes = item;
  //   }
  // });

  // const keys = Object.keys(indexes);
  // const columns = [];
  // columns.push(keys);

  // data.forEach((item, index) => {
  //   let aux = [];
  //   keys.sort().forEach((key) => {
  //     let value = item[key];
  //     if (key === "phone" && value && value !== String()) {
  //       value = value.code + value.number;
  //     }

  //     if (typeof value === "number") {
  //       value = String(value);
  //     }

  //     if ((key === "ranges" || key === "starterKit") && value) {
  //       value = String();
  //     }

  //     if (value === undefined || value === null) {
  //       value = String();
  //     }
  //     // console.log(value);
  //     // console.log(key);
  //     value = value.replace(",", String());

  //     if (key === "parentDealerName" && value) {
  //       console.log(value);
  //     }

  //     aux.push(value);
  //   });
  //   columns.push(aux);
  // });

  // let csvContent = "";
  // columns.forEach((row) => {
  //   // console.log(row.join(","));
  //   // let r = row.map((i) => {});
  //   let au = "";
  //   row.forEach((r, index) => {
  //     if (index === row.length - 1) {
  //       au += r;
  //     } else {
  //       au += r + ",";
  //     }
  //   });
  //   csvContent += au.replaceAll('"', '""') + "\n";
  // });
  const csvContent = [];

  // Obtener todas las llaves únicas de todos los objetos
  const allKeys = Array.from(new Set(data.flatMap((obj) => Object.keys(obj))));

  // Agregar encabezados al CSV y escapar valores
  csvContent.push(allKeys.map((key) => `"${key}"`).join(","));
  log(csvContent);
  // Agregar filas al CSV y escapar y reemplazar valores
  data.forEach((obj) => {
    const values = allKeys.map((key) => {
      const value = obj[key] !== undefined ? getStringOfObject(key, obj) : "";
      return `"${value.replace(/"/g, '""')}"`; // Reemplazar " con ""
    });
    csvContent.push(values.join(","));
  });
  return csvContent;
};

const getStringOfObject = (key, obj) => {
  if (key === "phone" && obj[key] && obj[key] !== String()) {
    return String(obj.code + obj.number);
  }

  if ((key === "ranges" || key === "starterKit") && obj[key]) {
    return String();
  }
  if (obj[key] == null) {
    return String();
  }
  return String();
};

// function exportarACSV(objeto, nombreArchivo) {
//   const delimiter = ","; // Delimitador de campo

//   // Obtener las claves del objeto como encabezados de columna
//   const columnas = Object.keys(objeto[0]);

//   // Crear el contenido CSV
//   let csv = columnas.join(delimiter) + "\n"; // Encabezados de columna

//   objeto.forEach((item) => {
//     const fila = columnas.map((columna) => {
//       let valor = item[columna];

//       // Si el valor contiene el delimitador, se envuelve entre comillas dobles
//       if (valor && valor.includes(delimiter)) {
//         valor = '"' + valor.replace(/"/g, '""') + '"';
//       }

//       return valor;
//     });

//     csv += fila.join(delimiter) + "\n"; // Agregar la fila al CSV
//   });

//   // Crear un enlace de descarga para el archivo CSV
//   const enlace = document.createElement("a");
//   enlace.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
//   enlace.target = "_blank";
//   enlace.download = nombreArchivo + ".csv";

//   // Simular el clic en el enlace para descargar el archivo
//   document.body.appendChild(enlace);
//   enlace.click();
//   document.body.removeChild(enlace);
// }
module.exports = { createCSVFile };
