import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import BasePage from "../BasePage";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";
import {
  Box,
  Grid,
  Button,
  AppBar,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Paper,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getCommissionsActions } from "../../actions/CommissionsActions";
import UISelect from "../../components/General/UISelect";
import { log } from "../../helpers/Logger";
import dayjs from "dayjs";
import weekdayPlugin from "dayjs/plugin/weekday";
import DialogModal from "../../components/General/DialogModal";
import { makeStyles } from "@mui/styles";
dayjs.extend(weekdayPlugin);

const findMonday = () => {
  let aux = dayjs();
  while (aux.day() !== Number()) {
    aux = aux.subtract(1, "days");
  }
  aux = aux = aux.subtract(6, "days");
  return aux;
};

const useStyles = makeStyles({
  myCustomStyles: {
    border: "0.5px solid gray",
  },
});

export default function CommissionsListPage(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [type, setType] = useState(Number());
  // const [openDialog, setOpenDialog] = useState(Boolean());
  const [commissionSelected, setCommissionSelected] = useState(null);
  const commissionsState = useSelector((state) => state.commissionsState);
  const [selectDate, setSelectDate] = useState(findMonday());

  const getCommissionsRemote = useCallback(async () => {
    let date = `${selectDate
      .add(6, "days")
      .format("YYYY-MM-DD")}-${selectDate.format("DD")}`;
    dispatch(getCommissionsActions(type, date));
  }, [selectDate, dispatch, type]);

  useEffect(() => {
    if (commissionsState.commissions === null) {
      getCommissionsRemote();
    }
  }, [getCommissionsRemote, commissionsState]);

  const myClasses = useStyles();

  const renderCustomCell = (text, align = "left") => (
    <TableCell
      classes={{
        root: myClasses.myCustomStyles,
      }}
      align={align}
    >
      {text}
    </TableCell>
  );

  const renderTable = () => {
    let view = null;
    if (commissionsState.commissions) {
      if (commissionsState.commissions.length > 0) {
        view = (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {renderCustomCell(t("date"))}
                  {renderCustomCell(t("name"), "right")}
                  {renderCustomCell(t("dealerName"), "right")}
                  {renderCustomCell(t("volume"), "right")}
                  {renderCustomCell(t("order"), "right")}
                </TableRow>
              </TableHead>
              <TableBody>
                {commissionsState.commissions.map((item) => (
                  <TableRow
                    key={item.order}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      setCommissionSelected(item);
                    }}
                  >
                    <TableCell align="left" component="th" scope="row">
                      {new Date(item.date).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="right">{item.name}</TableCell>
                    <TableCell align="right">{item.dealerName}</TableCell>
                    <TableCell align="right">
                      {item.commissionsVolume}
                    </TableCell>
                    <TableCell align="right">{item.order}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
    } else {
      view = <CircularProgress />;
    }
    return (
      <Box>
        <Typography variant="h5">{t("orders")}</Typography>
        {view}
      </Box>
    );
  };

  const renderEnrollmentsTable = () => {
    let view = null;
    if (commissionsState.enrollments) {
      if (commissionsState.enrollments.length > 0) {
        view = (
          <Box>
            <Typography variant="h5">{t("enrollments")}</Typography>
            <TableContainer component={Paper}>
              <Table
                classes={{
                  root: myClasses.myCustomStyles,
                }}
                sx={{ minWidth: 650 }}
              >
                <TableHead>
                  <TableRow>
                    {renderCustomCell(t("date"))}
                    {renderCustomCell(t("name"))}
                    {renderCustomCell(t("sponsorName"))}
                    {renderCustomCell(t("volume"))}
                    {renderCustomCell(t("order"))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {commissionsState.enrollments.map((item) => (
                    <TableRow
                      key={item.order}
                      onClick={() => {
                        setCommissionSelected(item);
                      }}
                    >
                      <TableCell align="left" scope="row">
                        {new Date(item.date).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="right">{item.name}</TableCell>
                      <TableCell align="right">{item.sponsorName}</TableCell>
                      <TableCell align="right">
                        {item.commissionsVolume}
                      </TableCell>
                      <TableCell align="right">{item.order}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );
      }
    }
    return view;
  };

  const renderView = () => {
    return <React.Fragment>{renderTable()}</React.Fragment>;
  };

  const renderToolBar = () => (
    <AppBar
      position="static"
      color="default"
      align="center"
      elevation={0}
      sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
    >
      <Toolbar>
        <Grid container spacing={2} alignItems="center">
          {/* <Grid item>
          <SearchIcon color="inherit" sx={{ display: "block" }} />
        </Grid> */}
          {/* <Grid item xs>
          <TextField
            fullWidth
            placeholder="Search by email address, phone number, or user UID"
            InputProps={{
              disableUnderline: true,
              sx: { fontSize: "default" },
            }}
            variant="standard"
          />
        </Grid> */}
          <Grid item xs />
          <Grid item>
            <UISelect
              title={t("commission")}
              label={t("commission")}
              value={type}
              options={[
                { name: t("weekly"), value: 0 },
                { name: t("monthly"), value: 1 },
              ]}
              onChange={(event) => {
                setType(event.target.value);
              }}
            />
          </Grid>

          {/* <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("startDate")}
              inputFormat="DD-MM-YYYY"
              value={new Date()}
              shouldDisableDate={(date) => date.day() !== 1}
              renderInput={(props) => <TextField {...props} />}
            />
          </LocalizationProvider>
        </Grid> */}

          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("fromDate")}
                inputFormat={type === 0 ? "DD-MM-YYYY" : "MM-YYYY"}
                value={selectDate}
                openTo={type === 0 ? "day" : "month"}
                shouldDisableDate={(date) => date.day() !== 1}
                views={
                  type === Number()
                    ? ["year", "month", "day"]
                    : ["year", "month"]
                }
                renderInput={(props) => <TextField {...props} />}
                maxDate={findMonday()}
                onChange={(date) => {
                  setSelectDate(date);
                }}
                onClose={(event) => {
                  console.log("================================CLOSE");
                  console.log(event);
                  console.log("====================================");
                }}
                onOpen={(event) => {
                  console.log("================================OPEN");
                  console.log(event);
                  console.log("====================================");
                }}
                onAccept={(value) => {
                  log(`ON ACCEPT ${value}`);
                }}
              />
            </LocalizationProvider>
          </Grid>

          {type === 0 && (
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled
                  inputFormat="DD-MM-YYYY"
                  value={selectDate.add(6, "days")}
                  label={t("toDate")}
                  renderInput={(props) => <TextField {...props} />}
                  onChange={(event) => {}}
                />
              </LocalizationProvider>
            </Grid>
          )}

          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                getCommissionsRemote();
              }}
              sx={{ mr: 1 }}
            >
              {t("consult")}
            </Button>
          </Grid>
          <Grid item xs />
          {/* <Grid item>
          <Tooltip title="Reload">
            <IconButton>
              <RefreshIcon color="inherit" sx={{ display: "block" }} />
            </IconButton>
          </Tooltip>
        </Grid> */}
        </Grid>
      </Toolbar>
    </AppBar>
  );

  const handleTextChange = (e) => {
    const value = e.target.value;
    // if (e.target.name === "password" || e.target.name === "confirmPassword") {
    //   value = crypt(value);
    // }
    // setData({
    //   ...data,
    //   [e.target.name]: value,
    // });
  };

  const renderCommission = () => {
    return (
      commissionSelected && (
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                onChange={handleTextChange}
                value={commissionSelected.date}
                disabled={true}
                margin="normal"
                required
                fullWidth
                name="date"
                label={t("date")}
                type="text"
                id="date"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={commissionSelected.name}
                onChange={handleTextChange}
                disabled={true}
                margin="normal"
                required
                fullWidth
                name="name"
                label={t("name")}
                type="text"
                id="name"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={commissionSelected.commissionsVolume}
                onChange={handleTextChange}
                margin="normal"
                required
                fullWidth
                name="volume"
                label={t("volume")}
                type="text"
                id="volume"
                autoComplete="volume"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={commissionSelected.order}
                onChange={handleTextChange}
                disabled={true}
                margin="normal"
                required
                fullWidth
                name="order"
                label={t("order")}
                type="text"
                id="order"
                autoComplete="order"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={
                  commissionSelected.dealerName ||
                  commissionSelected.sponsorName
                }
                onChange={handleTextChange}
                margin="normal"
                required
                fullWidth
                name="dealerName"
                label={t("dealer")}
                type="text"
                id="dealerName"
                autoComplete="dealerName"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={commissionSelected.dealerIdentifier}
                onChange={handleTextChange}
                margin="normal"
                required
                fullWidth
                name="dealerIdentifier"
                label={t("dealerIdentifier")}
                type="text"
                id="dealerIdentifier"
                autoComplete="dealerIdentifier"
              />
            </Grid>
          </Grid>
        </Box>
      )
    );
  };
  return (
    <BasePage title={t("commissions")}>
      <PaperBaseComponent>
        {renderToolBar()}
        {renderView()}
        {type === Number() && renderEnrollmentsTable()}
        <DialogModal
          open={commissionSelected !== null}
          onClose={() => setCommissionSelected(null)}
        >
          {renderCommission()}
        </DialogModal>
      </PaperBaseComponent>
    </BasePage>
  );
}
