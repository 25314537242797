import React from "react";
import { Routes, Route } from "react-router-dom";
import { EmailCreatorPage } from "../pages/Email/EmailCreatorPage";
import CampaingsListPage from "../pages/Email/CampaingsListPage";
import CreateCampaingPage from "../pages/Email/CreateCampaingPage";
import SendEmailPage from "../pages/Email/SendEmailPage";

const EmailRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<CampaingsListPage />} />
      <Route exact path="/createCampaign" element={<CreateCampaingPage />} />
      <Route exact path="/createEmail" element={<EmailCreatorPage />} />
      <Route exact path="/send" element={<SendEmailPage />} />
    </Routes>
  );
};

export default EmailRouter;
