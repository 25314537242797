import EndPoints from "../config/EndPoints.json";
import axios from "axios";
import { store } from "../store/store";
import { log } from "../helpers/Logger";

export const getAllProducts = (identifier = null) => {
  return new Promise(async (resolve, reject) => {
    const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.getAllProductsEndpoint}`;
    try {
      const token = store.getState().auth.token;

      let response = await axios.get(endpoint, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        params: {
          identifier,
        },
      });

      let result = response.data;
      if (result.code === 0) {
        resolve({ code: 0, data: result.data });
      } else {
        reject({ code: 1 });
      }
    } catch (error) {
      log(`ERROR: ${error}`);
      reject(error);
    }
  });
};
