import React, { useState } from "react";
import { useSelector } from "react-redux";
import LogoSweeven from "../resources/images/logo_sweeven_dark.svg";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import { ListItem, ListItemButton, ListItemIcon } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import InventoryIcon from "@mui/icons-material/Inventory";
import TollIcon from "@mui/icons-material/Toll";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { RoleType } from "../resources/Menus";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import EmailIcon from "@mui/icons-material/Email";
import TaskIcon from "@mui/icons-material/Task";
import AssessmentIcon from "@mui/icons-material/Assessment";

export default function Navigator(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const user = useSelector((state) => state.user);
  const INITIAL_VALUE = [
    {
      identifier: "finances",
      order: 0,
      name: t("finances"),
      icon: <AttachMoneyIcon />,
      toRoute: "/finance",
      active: true,
      childrens: [
        {
          identifier: "1",
          name: t("validator"),
          toRoute: "/finance/validator",
          active: Boolean(),
        },
        {
          identifier: "2",
          name: t("sellsByDay"),
          toRoute: "/finance/sellsByDay",
          active: Boolean(),
        },
        {
          identifier: "3",
          name: t("movements"),
          toRoute: "/finance/movements",
          active: Boolean(),
        },
      ],
    },
    {
      identifier: "accounts",
      order: 1,
      name: t("accounts"),
      icon: <SupervisorAccountIcon />,
      toRoute: "/accounts",
      active: true,
      childrens: [
        {
          identifier: "accounts/register",
          name: t("register"),
          toRoute: "/accounts/register",
          active: Boolean(),
        },
      ],
    },
    {
      order: 2,
      identifier: "dealers",
      name: t("dealers"),
      icon: <Diversity3Icon />,
      toRoute: String(),
      active: true,
      childrens: [
        {
          identifier: "dealers/list",
          name: t("list"),
          toRoute: "/dealers",
          active: Boolean(),
        },
        {
          identifier: "dealers/add",
          name: t("add"),
          toRoute: "/dealers/add",
          active: Boolean(),
        },
        {
          identifier: "genealogy",
          name: t("genealogy"),
          toRoute: "/dealers/genealogy",
          active: Boolean(),
        },
      ],
    },
    {
      identifier: "autoshipping",
      order: 3,
      active: true,
      name: t("autoshipping"),
      icon: <LocalShippingIcon />,
      toRoute: "/autoshipping/list",
    },
    {
      identifier: "products",
      active: true,
      order: 4,
      name: t("products"),
      icon: <InventoryIcon />,
      toRoute: "/products",
    },
    {
      identifier: "commissions",
      active: true,
      order: 5,
      name: t("commissions"),
      icon: <TollIcon />,
      toRoute: "/commissions",
    },
    {
      identifier: "emails",
      active: true,
      order: 6,
      name: t("emails"),
      icon: <EmailIcon />,
      childrens: [
        {
          identifier: "emails/campaings",
          name: t("campaings"),
          toRoute: "/email",
          active: Boolean(),
        },
        {
          identifier: "emails/templates",
          name: t("templates"),
          toRoute: "/email/templates",
          active: Boolean(),
        },
        {
          identifier: "emails/send",
          name: t("sendEmail"),
          toRoute: "/email/send",
          active: Boolean(),
        },
      ],
    },
    {
      identifier: "costumers",
      active: true,
      order: 7,
      name: t("costumers"),
      icon: <Diversity1Icon />,
      childrens: [
        {
          identifier: "costumers/segments",
          name: t("segments"),
          toRoute: "/",
          active: Boolean(),
        },
      ],
    },
    {
      identifier: "funnelGenerator",
      active: false,
      order: 8,
      name: t("funnelGenerator"),
      icon: <FilterAltIcon />,
      toRoute: "/funnelGenerator",
    },
    {
      identifier: "reports",
      active: false,
      order: 9,
      name: t("reports"),
      icon: <AssessmentIcon />,
      toRoute: "/reports",
    },
    {
      identifier: "tasks",
      active: true,
      order: 10,
      name: t("tasks"),
      icon: <TaskIcon />,
      toRoute: "/tasks",
    },
  ];

  const [menu, setMenu] = useState(INITIAL_VALUE);
  const item = {
    py: "2px",
    px: 3,
    color: "rgba(255, 255, 255,0.8)",
    "&:hover, &:focus": {
      bgcolor: "rgba(255, 255, 255, 0.08)",
    },
  };

  const itemCategory = {
    boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
    py: 1.5,
    px: 3,
  };

  const { ...other } = props;
  let navigate = useNavigate();

  const handleItemSelected = (item) => {
    if (item.childrens) {
      setMenu((prev) => {
        let result = prev.find((menu) => menu.identifier === item.identifier);
        result.selected = !result.selected;
        console.log("====================================");
        console.log(result);
        console.log("====================================");
        let filtered = prev.filter((x) => x.identifier !== item.identifier);
        filtered.push(result);
        return filtered;
      });
    } else if (item.externalLink) {
      window.location.href = item.externalLink;
    } else {
      navigate(item.toRoute);
    }
  };

  const renderArrow = (selected, childrens) => {
    if (childrens) {
      return (
        <KeyboardArrowDown
          sx={{
            mr: -1,
            opacity: 0,
            transform: selected ? "rotate(-180deg)" : "rotate(0)",
            transition: "0.2s",
          }}
        />
      );
    } else {
      return null;
    }
  };

  const news = (open) => {
    return {
      py: 2,
      px: 3,
      pb: open ? 0 : 2.5,
      "&:hover, &:focus": { "& svg": { opacity: 1 } },
    };
  };

  const renderMenu = () => {
    return menu
      .filter(
        (menu) => menu.active && RoleType[user.role].includes(menu.identifier)
      )
      .sort((a, b) => a.order - b.order)
      .map((item) => {
        return (
          <Box
            key={item.identifier}
            sx={{
              color: "rgba(255, 255, 255,0.8)",
              bgcolor: item.selected ? "rgba(99, 99, 90, 0.2)" : null,
              pb: item.selected ? 2 : 0,
            }}
          >
            <ListItemButton
              sx={{ ...news(item.selected) }}
              onClick={() => {
                handleItemSelected(item);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.name}</ListItemText>
              {item.secondaryIcon && (
                <ListItemIcon>{item.secondaryIcon}</ListItemIcon>
              )}
              {renderArrow(item.selected, item.childrens)}
            </ListItemButton>
            {/* TODO: ADD FILTER BY ACTIVE OR NOT EXAMPLE LINE 238 */}
            {item.selected &&
              item.childrens.map((children) => (
                <ListItemButton
                  key={children.identifier}
                  onClick={() => {
                    handleItemSelected(children);
                  }}
                  sx={{
                    py: 0,
                    minHeight: 32,
                    color: "rgba(255,255,255,.8)",
                    ml: 6,
                  }}
                >
                  <ListItemText
                    primary={children.name}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  ></ListItemText>
                </ListItemButton>
              ))}
          </Box>
        );
      });
  };

  // const renderChildrens = (childrens) => {
  //   return childrens.map((children) => (
  //     <Box key={children.identifier}>
  //       <ListItem disablePadding>
  //         <ListItemButton
  //           selected={children.active}
  //           sx={{ ...item, pl: 4 }}
  //           onClick={() => {
  //             navigate(`${children.toRoute}`);
  //           }}
  //         >
  //           <ListItemText>{children.name}</ListItemText>
  //         </ListItemButton>
  //       </ListItem>
  //     </Box>
  //   ));
  // };

  return (
    <Drawer variant="permanent" {...other}>
      <List component="nav">
        <ListItem
          sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}
          onClick={() => {
            navigate("/home");
          }}
        >
          <img
            alt={"logo"}
            style={{
              width: "210px",
              height: "auto",
              margin: "auto",
            }}
            src={LogoSweeven}
          />
        </ListItem>
        {renderMenu()}
      </List>
    </Drawer>
  );
}
