import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper,
  AppBar,
  Toolbar,
  Grid,
  TextField,
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  getLinearProgressUtilityClass,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import BasePage from "../BasePage";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";
import UIDateSelectComponent from "../../components/General/UIDateSelectComponent";
import * as dayjs from "dayjs";

export default function SellByDayPage() {
  const { t } = useTranslation();
  const [needData, setNeedData] = useState(true);
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);

  const getDaysOfMonth = () => {
    let currentDate = new Date();
    let month = currentDate.getMonth();
    let year = currentDate.getFullYear();
    let lastDayDate = new Date(year, month + 1, 0).getDate();
    let aux = [];
    for (var i = 1; i <= lastDayDate; i++) {
      aux.push(
        <TableCell key={i} align="right">
          {i}
        </TableCell>
      );
    }
    return aux;
  };

  let route = "http://localhost:4000/finance/orderByMonth?";

  const getOrders = useCallback(async () => {
    fetch(
      route +
        new URLSearchParams({
          month: Number(selectedDate.month()),
          year: Number(selectedDate.year()),
        }),
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setNeedData(Boolean());
        setProducts(result.data);
        console.log(result.data);
      })
      .catch((error) => {
        console.log("ERROR");
        console.log(error);
        setNeedData(Boolean());
      });
  }, [route, selectedDate]);

  useEffect(() => {
    console.log("1");
    if (needData) {
      getOrders();
    }
  }, [needData, getOrders]);

  // const filterProducts = (products) => {
  //   let aux = [];
  //   orders.forEach((order) => {
  //     aux.push(order.movements);
  //   });

  //   let uniqueOrders = [
  //     ...new Set(aux.map((movement) => movement.lineitemName)),
  //   ];
  //   setMovementsList(uniqueOrders);
  //   // setMovements(aux);
  // };

  const renderSells = (sells) => {
    let lastDayDate = new Date(
      selectedDate.year(),
      selectedDate.month() + 1,
      0
    ).getDate();
    let rows = [];
    for (let i = 1; i <= lastDayDate; i++) {
      rows.push(
        <TableCell key={i} align="right">
          {Intl.NumberFormat("es-MX").format(sells[i])}
        </TableCell>
      );
    }
    return rows;
    // return sells.map((sell) => {
    //   return <TableCell align="right">{sell}</TableCell>;
    // });
  };

  const sortArray = (array) => {
    return array.sort((item1, item2) => {
      const name1 = item1.lineitemName.toUpperCase();
      const name2 = item2.lineitemName.toUpperCase();
      if (name1 < name2) {
        return -1;
      } else if (name1 > name2) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const renderView = () => {
    if (products) {
      return products.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">{t("product")}</TableCell>
                {getDaysOfMonth()}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortArray(products).map((product) => (
                <TableRow
                  key={product.lineitemName}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left" component="th" scope="row">
                    {product.lineitemName}
                  </TableCell>
                  {renderSells(product.sells)}
                  {/* <TableCell align="right">{"asdas"}</TableCell>
                  <TableCell align="right">{}</TableCell>
                  <TableCell align="right">{}</TableCell>
                  <TableCell align="right">{}</TableCell>
                  <TableCell align="right">{}</TableCell>
                  <TableCell align="right">{}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress />
      );
    } else {
      return <CircularProgress />;
    }
  };

  return (
    <BasePage title={t("sellsByDay")}>
      <PaperBaseComponent>
        <AppBar
          position="static"
          color="default"
          align="center"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Toolbar>
            <Grid container spacing={0} alignItems="center">
              <Grid item>
                <SearchIcon color="inherit" sx={{ display: "block" }} />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by email address, phone number, or user UID"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: "default" },
                  }}
                  variant="standard"
                />
              </Grid>
              <Grid item>
                <Tooltip title="Reload">
                  <IconButton>
                    <RefreshIcon color="inherit" sx={{ display: "block" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
          <Stack>
            <UIDateSelectComponent
              value={selectedDate}
              onAccept={(date) => {
                console.log(`MONTH: ${date.month()}`);
                setSelectedDate(date);
                setNeedData(true);
              }}
            />
          </Stack>
        </AppBar>
        {renderView()}
      </PaperBaseComponent>
    </BasePage>
  );
}
