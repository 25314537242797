import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BasePage from "../BasePage";
import UIButton from "../../components/General/UIButton";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";
import { UserDataComponent } from "../../components/General/UserDataComponent";
import { AddresViewComponent } from "../../components/General/AddresViewComponent";
import { VolumeViewComponent } from "../../components/Dealers/VolumeViewComponent";
import { getUserAddressService } from "../../APIServices/UserAPIService";
import { useSpring, animated, config } from "@react-spring/web";

import { log } from "../../helpers/Logger";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const INITIAL_FIELDS = {
  firstName: String(),
  lastName: String(),
  email: String(),
  confirmEmail: String(),
  dealerURL: String(),
  phone: String(),
  rfc: String(),
};

export default function DetailDealerPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dealerSelected = useSelector((state) => state.dealers.dealerSelected);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);

  const getAddressService = useCallback(async (identier) => {
    try {
      let addressResponse = await getUserAddressService(identier);
      if (addressResponse.code === Number()) {
        return addressResponse.data;
      }
      log(addressResponse);
      return null;
    } catch {
      return null;
    }
  }, []);

  const renderShippingAddressView = () => {
    let view = <CircularProgress />;
    if (shippingAddress) {
      view = <AddresViewComponent address={shippingAddress} />;
    }
    return view;
  };

  const renderBillingAddressView = () => {
    let view = <CircularProgress />;
    if (billingAddress) {
      view = <AddresViewComponent address={billingAddress} />;
    }
    return view;
  };
  const [styles, api] = useSpring(() => ({}));
  const renderView = () => {
    console.log(dealerSelected);
    return (
      <animated.div style={styles}>
        <Stack spacing={3}>
          <Box>
            <IconButton
              aria-label="delete"
              onClick={() => {
                navigate("/dealers");
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <UIButton title={t("edit")} />
          </Box>
          <VolumeViewComponent
            volume={{
              commissionPoints: dealerSelected.commissionPoints,
              commissionPending: dealerSelected.commissionPending,
              calificationPoints: dealerSelected.calificationPoints,
              calificationPending: dealerSelected.calificationPending,
              gvp: dealerSelected.gvp,
            }}
          />
          <UserDataComponent
            user={dealerSelected}
            handleGoToDealer={() => {
              api.start({
                from: { opacity: "1" },
                to: [{ opacity: "0" }, { opacity: "1" }],
                config: { duration: "100" },
                loop: false,
              });
            }}
          />

          <Accordion
            onChange={async () => {
              log(dealerSelected.shippingAddress);
              if (shippingAddress === null) {
                setShippingAddress(
                  await getAddressService(dealerSelected.shippingAddress)
                );
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ fontSize: 42 }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">{t("shippingAddress")}</Typography>
            </AccordionSummary>
            <AccordionDetails>{renderShippingAddressView()}</AccordionDetails>
          </Accordion>

          <Accordion
            onChange={async () => {
              if (billingAddress === null) {
                if (
                  dealerSelected.shippingAddress ===
                  dealerSelected.billingAddress
                ) {
                  setBillingAddress(shippingAddress);
                } else {
                  setBillingAddress(
                    await getAddressService(dealerSelected.billingAddress)
                  );
                }
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ fontSize: 42 }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">{t("billingAddress")}</Typography>
            </AccordionSummary>
            <AccordionDetails>{renderBillingAddressView()}</AccordionDetails>
          </Accordion>
        </Stack>
      </animated.div>
    );
  };

  return (
    <BasePage title={t("dealer")}>
      <PaperBaseComponent>{renderView()}</PaperBaseComponent>
    </BasePage>
  );
}
