import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper,
  AppBar,
  Toolbar,
  Grid,
  TextField,
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import BasePage from "../BasePage";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";
import UIDateSelectComponent from "../../components/General/UIDateSelectComponent";
import { getAutoshippingService } from "../../APIServices/AutoShippingAPIService";
import * as dayjs from "dayjs";
import UIButton from "../../components/General/UIButton";

export default function CreateCampaingPage() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [orders, setOrders] = useState([]);
  const [viewData, setViewData] = useState({
    needData: true,
    loading: Boolean(),
    success: Number(),
  });

  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const getOrders = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });

    let response = {};
    try {
      response = await getAutoshippingService();
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loading: Boolean(),
    });
    console.log(`RESPONSE: ${JSON.stringify(response, null, 2)}`);
    if (response.code === 0) {
      setOrders(response.data);
    }
  }, []);

  useEffect(() => {
    if (viewData.needData) {
      //   getOrders();
    }
  }, [viewData.needData, getOrders]);

  const renderView = () => {
    return (
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField fullWidth margin="normal" label={t("name")}></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              placeholder={t("emailSubject")}
              label={t("subject")}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant={"outlined"}
              onClick={() => {
                navigation("/email/createEmail");
              }}
            >
              {t("createBody")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant={"contained"}>
              {"createCampaing"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <BasePage title={t("campaings")}>
      <PaperBaseComponent>{renderView()}</PaperBaseComponent>
    </BasePage>
  );
}
