import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper,
  AppBar,
  Toolbar,
  Grid,
  TextField,
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import BasePage from "../BasePage";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";
import ProductTemplate from "../../components/Products/ProductTemplate";
import UIDateSelectComponent from "../../components/General/UIDateSelectComponent";
import { getAllProducts } from "../../APIServices/ProductsAPIService";
import * as dayjs from "dayjs";

export default function ProductDetailPage() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const location = useLocation();
  const [product, setProduct] = useState(null);
  const [data, setData] = useState(null);
  const [viewData, setViewData] = useState({
    needData: true,
    loading: Boolean(),
    success: Number(),
  });

  const getData = useCallback(async (identifier) => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });

    let response = {};
    try {
      response = await getAllProducts(identifier);
      if (response.code === Number()) {
        setData(response.data);
      }
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loading: Boolean(),
    });
    console.log(`RESPONSE: ${JSON.stringify(response, null, 2)}`);
    if (response.code === 0) {
      setData(response.data);
    }
  }, []);

  useEffect(() => {
    const product = location.state ? location.state.data : {};
    if (viewData.needData && product) {
      getData(product.identifier.split("/").pop());
    }
  }, [viewData.needData, getData, location.state]);

  const renderView = () => {
    return data ? <ProductTemplate data={data} /> : <CircularProgress />;
  };

  return (
    <BasePage title={t("product")}>
      <PaperBaseComponent>{renderView()}</PaperBaseComponent>
    </BasePage>
  );
}
