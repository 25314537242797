import React from "react";
import { Routes, Route } from "react-router-dom";
import AutoShippingListPage from "../pages/AutoShipping/AutoShippingListPage";
import AutoShippingDetailPage from "../pages/AutoShipping/AutoShippingDetailPage";

const AutoShippingRouter = () => {
  return (
    <Routes>
      <Route exact path="/list" element={<AutoShippingListPage />} />
      <Route exact path="/detail" element={<AutoShippingDetailPage />} />
    </Routes>
  );
};

export default AutoShippingRouter;
