import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper,
  AppBar,
  Toolbar,
  Grid,
  TextField,
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import BasePage from "../BasePage";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";
import UIDateSelectComponent from "../../components/General/UIDateSelectComponent";
import { getAllProducts } from "../../APIServices/ProductsAPIService";
import * as dayjs from "dayjs";

export default function ProductsListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [viewData, setViewData] = useState({
    needData: true,
    loading: Boolean(),
    success: Number(),
  });

  const getData = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });

    let response = {};
    try {
      response = await getAllProducts();
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loading: Boolean(),
    });
    console.log(`RESPONSE: ${JSON.stringify(response, null, 2)}`);
    if (response.code === 0) {
      setData(response.data);
    }
  }, []);

  useEffect(() => {
    if (viewData.needData) {
      getData();
    }
  }, [viewData.needData, getData]);

  const renderView = () => {
    return data.length > 0 ? (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{t("sku")}</TableCell>
              <TableCell align="left">{t("title")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow
                key={item.title}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
                onClick={() => {
                  navigate("detail", { state: { data: item } });
                }}
              >
                <TableCell align="left" component="th" scope="row">
                  {item.variants[Number()].sku}
                </TableCell>
                <TableCell align="left" component="th" scope="row">
                  {item.title}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <CircularProgress />
    );
  };

  return (
    <BasePage title={t("products")}>
      <PaperBaseComponent>
        <AppBar
          position="static"
          color="default"
          align="center"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Toolbar>
            <Grid container spacing={0} alignItems="center">
              <Grid item>
                <SearchIcon color="inherit" sx={{ display: "block" }} />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by email address, phone number, or user UID"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: "default" },
                  }}
                  variant="standard"
                />
              </Grid>
              <Grid item>
                <Tooltip title="Reload">
                  <IconButton>
                    <RefreshIcon color="inherit" sx={{ display: "block" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {renderView()}
      </PaperBaseComponent>
    </BasePage>
  );
}
