// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  collection,
  addDoc,
  onSnapshot,
  query,
  updateDoc,
  getDocs,
  writeBatch,
  orderBy,
  where,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
//import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBbAw1gMyB0qeHEQ4u6IBqV-tEGZBvaSYQ",
  authDomain: "life-sweeven-tools.firebaseapp.com",
  projectId: "life-sweeven-tools",
  storageBucket: "life-sweeven-tools.appspot.com",
  messagingSenderId: "393768963006",
  appId: "1:393768963006:web:9c7d49fcc9d9de587a07ef",
  measurementId: "G-SQNG4R96GJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const db = getFirestore();
const auth = getAuth();
//const messaging = getMessaging();

export {
  app,
  db,
  doc,
  getDoc,
  auth,
  writeBatch,
  query,
  getDocs,
  orderBy,
  where,
  setDoc,
  collection,
  addDoc,
  updateDoc,
  onSnapshot,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
};
