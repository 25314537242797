import React, { useState } from "react";
import { Paper, Button, Stack, Typography } from "@mui/material";
import BasePage from "../BasePage";
import { useTranslation } from "react-i18next";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";

export default function FinancePage() {
  const [file, setFile] = useState();
  const { t } = useTranslation();
  return (
    <BasePage title={t("finances")}>
      <PaperBaseComponent>
        <Button variant="contained">{t("uploadFile")}</Button>
      </PaperBaseComponent>
    </BasePage>
  );
}
