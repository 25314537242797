import { Button, AppBar, Toolbar } from "@mui/material";
import { t } from "i18next";
import React, { useRef } from "react";
import { render } from "react-dom";

import EmailEditor from "react-email-editor";

export const EmailCreatorPage = (props) => {
  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log(JSON.stringify(design));
      console.log("exportHtml", html.replace("&lt;", "<"));
    });
  };

  const onReady = () => {
    // editor is ready
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  return (
    <div>
      <div>
        <AppBar color="primary" position="sticky" elevation={0}>
          <Toolbar>
            <Button
              variant={"contained"}
              onClick={exportHtml}
              sx={{ bgColor: "white" }}
            >
              {t("save")}
            </Button>
          </Toolbar>
        </AppBar>
      </div>

      <EmailEditor
        style={{ width: "100vw", minHeight: "96vh" }}
        ref={emailEditorRef}
        onReady={onReady}
      />
    </div>
  );
};
