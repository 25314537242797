import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper,
  AppBar,
  Toolbar,
  Grid,
  TextField,
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import BasePage from "../BasePage";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";
import UIDateSelectComponent from "../../components/General/UIDateSelectComponent";
import * as dayjs from "dayjs";

export default function MovementsPage() {
  const [file, setFile] = useState();
  const [needData, setNeedData] = useState(true);
  const [movements, setMovements] = useState([]);
  // const [total, setTotal] = useState(Number());
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const { t } = useTranslation();

  let route = "http://localhost:4000/finance/movements?";

  const getOrders = useCallback(async () => {
    fetch(
      route +
        new URLSearchParams({
          month: Number(selectedDate.month()),
          year: Number(selectedDate.year()),
        }),
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setNeedData(Boolean());
        setMovements(result.data);
        console.log(result.data);
      })
      .catch((error) => {
        console.log("ERROR");
        console.log(error);
        setNeedData(Boolean());
      });
  }, [route, selectedDate]);

  useEffect(() => {
    console.log("1");
    if (needData) {
      getOrders();
    }
  }, [needData, getOrders]);

  const getTotalPrice = (movement) => {
    let price =
      movement.currency === "USD"
        ? movement.lineitemPrice * 19
        : movement.lineitemPrice;
    return Intl.NumberFormat("es-MX").format(
      price * movement.lineitemQuantity - movement.lineitemDiscount
    );
  };

  const getTotalOfProducts = () => {
    if (movements.length > 0) {
      let result = movements.reduce((total, movement) => {
        console.log(movement);
        return (
          total +
          (movement.lineitemPrice * movement.lineitemQuantity -
            movement.lineitemDiscount)
        );
      }, 0);
      console.log("Result");
      console.log(result);
      return (
        <Typography>{`${t("total")} ${Intl.NumberFormat("es-MX").format(
          result
        )} MXN`}</Typography>
      );
    }
  };

  const renderView = () => {
    return movements.length > 0 ? (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{t("date")}</TableCell>
              <TableCell align="right">{t("concept")}</TableCell>
              <TableCell align="right">{t("shipping")}</TableCell>
              <TableCell align="right">{t("conversion")}</TableCell>
              <TableCell align="right">{t("code")}</TableCell>
              <TableCell align="left">{t("product")}</TableCell>
              <TableCell align="right">{t("paymentPlatform")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {movements.map((movement) => (
              <TableRow
                key={movement.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left" component="th" scope="row">
                  {movement.createdAt}
                </TableCell>
                <TableCell align="right">{movement.orderNumber}</TableCell>
                <TableCell align="right">{movement.shipping}</TableCell>
                <TableCell align="right">{getTotalPrice(movement)}</TableCell>
                <TableCell align="right">{movement.lineitemSku}</TableCell>
                <TableCell align="left">{movement.lineitemName}</TableCell>
                <TableCell align="right">{movement.paymentMethod}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <CircularProgress />
    );
  };

  return (
    <BasePage title={t("movements")}>
      <PaperBaseComponent>
        <AppBar
          position="static"
          color="default"
          align="center"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Toolbar>
            <Grid container spacing={0} alignItems="center">
              <Grid item>
                <SearchIcon color="inherit" sx={{ display: "block" }} />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by email address, phone number, or user UID"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: "default" },
                  }}
                  variant="standard"
                />
              </Grid>
              <Grid item>
                <Tooltip title="Reload">
                  <IconButton>
                    <RefreshIcon color="inherit" sx={{ display: "block" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
          <Stack spacing={2}>
            <UIDateSelectComponent
              value={selectedDate}
              onAccept={(date) => {
                console.log(`MONTH: ${date.month()}`);
                setSelectedDate(date);
                setNeedData(true);
              }}
            />
            {getTotalOfProducts()}
          </Stack>
        </AppBar>
        {renderView()}
      </PaperBaseComponent>
    </BasePage>
  );
}
