import * as React from "react";
import { motion } from "framer-motion";
import { CssBaseline, Paper, Container } from "@mui/material";
import Box from "@mui/material/Box";

export default function PaperBaseComponent(props) {
  return (
    <motion.div
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      transition={{ ease: "easeInOut", duration: 0.15 }}
    >
      <Paper
        {...props}
        sx={{
          bgcolor: "#FFF",

          overflow: "hidden",
          // maxWidth: "md",
        }}
      >
        <Container component="main">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
            }}
          >
            {props.children}
          </Box>
        </Container>
      </Paper>
    </motion.div>
  );
}
