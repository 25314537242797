import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tree from "react-d3-tree";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

export const TreeChart = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [urlValid, setUrlValid] = useState(Boolean());

  useEffect(() => {}, []);

  const renderImageAvatar = () => (
    <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern
          id="image"
          x="0"
          y="0"
          patternUnits="userSpaceOnUse"
          width="100"
          height="100"
        >
          <image x="0" y="0" width="100" height="100" src={""} />
        </pattern>
      </defs>

      <circle cx="50" cy="50" r="40" fill="url(#image)" />
    </svg>
  );

  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
    <g>
      <svg
        onClick={toggleNode}
        width="60"
        height="60"
        x="-30"
        y={nodeDatum.__rd3t.depth === Number() ? "-60" : "0"}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
          strokeWidth="1.5"
          stroke="currentColor"
        />
      </svg>

      <text fill="black" strokeWidth="0.5" x="40">
        {nodeDatum.name}
      </text>
      {Object.keys(nodeDatum.attributes).map((key, index) => {
        return (
          <text key={key + index} fill="black" strokeWidth="0.5" x="40" y="20">
            {`${key}: ${nodeDatum.attributes[key]}`}
          </text>
        );
      })}
    </g>
  );

  return (
    <div style={{ width: "80vw", height: "70vh" }}>
      <Tree
        pathFunc={"step"}
        translate={{ x: 0, y: 0 }}
        data={props.data}
        separation={{ nonSiblings: 1, siblings: 2.5 }}
        orientation="vertical"
        renderCustomNodeElement={renderRectSvgNode}
        // rootNodeClassName="node__root"
        // branchNodeClassName="node__branch"
        // leafNodeClassName="node__leaf"
      />
    </div>
  );
};
