import { UserTypes } from "../types/UserTypes";

let INITIAL_STATE = {};

export const UserReducer = (state = {}, action) => {
  switch (action.type) {
    case UserTypes.updateInfo:
      return {
        ...state,
        ...action.payload.data,
      };
    case UserTypes.clear:
      return INITIAL_STATE;

    default:
      return state;
  }
};
