import {
  db,
  collection,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "../firebase/ClientFirebaseConfig";
import { store } from "../store/store";
import axios from "axios";
import EndPoints from "../config/EndPoints.json";

const DB_COLLECTION_CONSTANT = "autoshipping";
// let urlHost = "http://localhost:4000/admin/";
let urlHost =
  "https://r7177wxb1f.execute-api.us-east-1.amazonaws.com/dev/admin/";

export const createAutoshippingService = (order) => {
  return new Promise(async (resolve, reject) => {
    try {
      var request = collection(db, `${DB_COLLECTION_CONSTANT}`);
      var orderReference = await addDoc(request, {
        email: order.contact.email,
        phone: order.contact.phone,
        products: order.products,
        shippingAddress: order.shippingAddress,
        billingAddress: order.billingAddress,
        interval: order.interval,
        createdAt: new Date(),
        mofifiedAt: new Date(),
        state: "active",
      });
      console.log(orderReference);
      resolve({ code: 0, data: orderReference });
    } catch (error) {
      reject({ code: 1, error: error });
      console.log(error);
    }
  });
};

/**
 *
 * @param {*} email
 * @returns
 */
// export const getAutoshippingService = () => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let aux = [];
//       const querySnapshot = await getDocs(
//         collection(db, DB_COLLECTION_CONSTANT)
//       );
//       querySnapshot.forEach((doc) => {
//         var data = doc.data();
//         data.identifier = doc.id;
//         data.interval.startedDate = data.interval.startedDate.toDate();
//         data.modifiedAt = data.modifiedAt.toDate();
//         data.createdAt = data.createdAt.toDate();
//         aux.push(data);
//       });

//       resolve({ code: 0, data: aux });
//     } catch (error) {
//       console.log(`ERROR: ${error}`);
//       reject({ code: 1, error: error });
//     }
//   });
// };

export const getAutoshippingService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = store.getState().auth.token;
      const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.getAutoShippingEndpoint}`;
      let data = [];
      let response = await axios.get(endpoint, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.data.code === 0) {
        data = response.data.data;
      } else {
      }
      // fetch(urlHost + "autoshipping", {
      //   method: "GET",
      //   headers: { authorization: `Bearer ${token}` },
      // })
      //   .then((res) => res.json())
      //   .then((result) => {
      //     if (result.code === 0) {
      //       data = result.data;
      //     } else {
      //     }
      //   })
      //   .catch((error) => {
      //     console.log("ERROR");
      //     console.log(error);
      //   });

      resolve({ code: 0, data: data });
    } catch (error) {
      console.log(`ERROR: ${error}`);
      reject({ code: 1, error: error });
    }
  });
};

export const updateAutoshippingService = (identifier, order) => {
  return new Promise(async (resolve, reject) => {
    var ref = doc(db, `${DB_COLLECTION_CONSTANT}`, identifier);
    let data = {
      email: order.email,
      phone: order.phone,
      products: order.products,
      shippingAddress: order.shippingAddress,
      billingAddress: order.billingAddress,
      interval: order.interval,
      mofifiedAt: new Date(),
    };
    try {
      await updateDoc(ref, data);
      resolve({ code: 0 });
    } catch (error) {
      console.log(error);
      resolve({ code: 1, data: error });
    }
  });
};

export const acceptAutoshippingService = (identifier, order) => {
  return new Promise(async (resolve, reject) => {
    var ref = doc(db, `${DB_COLLECTION_CONSTANT}`, identifier);
    let data = {
      status: "active",
    };
    try {
      await updateDoc(ref, data);
      resolve({ code: 0 });
    } catch (error) {
      console.log(error);
      resolve({ code: 1, data: error });
    }
  });
};

export const deleteAutoshippingService = (identifier) => {
  console.log(identifier);
  return new Promise(async (resolve, reject) => {
    try {
      await deleteDoc(doc(db, `${DB_COLLECTION_CONSTANT}`, identifier));
      resolve({ code: 0 });
    } catch (error) {
      console.log(error);
      reject({ code: 1, data: error });
    }
  });
};

export const pauseAutoshippingService = (identifier, order) => {
  return new Promise(async (resolve, reject) => {
    var ref = doc(db, `${DB_COLLECTION_CONSTANT}`, identifier);
    let data = {
      status: "pause",
    };
    try {
      await updateDoc(ref, data);
      resolve({ code: 0 });
    } catch (error) {
      console.log(error);
      console.log(error);
      resolve({ code: 1, data: error });
    }
  });
};
