import { Routes, Route } from "react-router-dom";

import React from "react";
import FinancePage from "../pages/Finance/FinancePage";
import MovementsPage from "../pages/Finance/MovementsPage";
import SellByDayPage from "../pages/Finance/SellByDayPage";
import ValidatorPage from "../pages/Finance/ValidatorPage";

const FinanceRouter = () => {
  return (
    <Routes>
      {/* <Route index element={<FinancePage />} /> */}
      <Route exact path="/" element={<FinancePage />} />
      <Route exact path="/movements" element={<MovementsPage />} />
      <Route exact path="/sellsByDay" element={<SellByDayPage />} />
      <Route exact path="/validator" element={<ValidatorPage />} />
      <Route path="*" element={<FinancePage />} />
    </Routes>
    // <Router>
    //   <Switch>
    //     <Route exact path="/" element={<FinancePage />} />
    //     <Route exact path="/movements" element={<MovementsPage />} />
    //   </Switch>
    // </Router>
  );
};

export default FinanceRouter;
