import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

export const VolumeViewComponent = (props) => {
  const { t } = useTranslation();

  const style = { textAlign: "center" };

  const [data, setData] = useState({
    commissionPoints: Number(),
    commissionPending: Number(),
    calificationPoints: Number(),
    calificationPending: Number(),
  });

  useEffect(() => {
    if (props.volume) {
      setData(props.volume);
    }
  }, [props.volume]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={style}>
          {t("volume")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" sx={style}>
          {`${t("calification")}: ${data.calificationPoints || Number()}`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" sx={style}>
          {`${t("commission")}: ${data.commissionPoints || Number()}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={style}>
          {t("pending")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" sx={style}>
          {`${t("calification")}: ${data.calificationPending || Number()}`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" sx={style}>
          {`${t("commission")}: ${data.commissionPending || Number()}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={style}>
          {t("gvp")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" sx={style}>
          {`${t("gvp")}: ${data.gvp || Number()}`}
        </Typography>
      </Grid>
    </Grid>
  );
};
