import * as React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

/**
 *
 */
export default function UISelect(props) {
  const renderOptions = () => {
    return props.options.map((option) => {
      let str = option.title ? option.title : option.name;
      return (
        <MenuItem key={option.value} value={option.value}>
          {str}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={props.labrlId}>{props.title}</InputLabel>
      <Select
        {...props}
        // labelId={props.labelId}
        // id={props.id}
        // value={selectedValue}
        // label={props.name}
        // onChange={handleChange}
      >
        {props.options && renderOptions()}
      </Select>
    </FormControl>
  );
}
