import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { login } from "../actions/AuthenticationAction";
import { auth, onAuthStateChanged } from "../firebase/firebaseConfig";
import FinanceRouter from "./FinanceRouter";
import AutoShippingRouter from "./AutoShippingRouter";
import CommissionsRouter from "./CommissionsRouter";
import DealersRouter from "./DealersRouter";
import ProductsRouter from "./ProductsRouter";
import EmailRouter from "./EmailRouter";
import SignInPage from "../pages/Authentication/SignInPage";
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";
import BasePage from "../pages/BasePage";
import ScrollToTop from "../helpers/ScrollToTop";

export const AppRouter = () => {
  // const [user, setUser] = useState(null);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   onAuthStateChanged(auth, (user) => {
  //     console.log(user);
  //     if (user) {
  //       dispatch(login(user.uid, user.displayName, user.accessToken));
  //       setUser(user);
  //     } else {
  //       setUser(null);
  //     }
  //   });
  // }, [dispatch]);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRouter>
              <BasePage />
            </PrivateRouter>
          }
        />
        <Route
          path="/signin"
          element={
            <PublicRouter>
              <SignInPage />
            </PublicRouter>
          }
        />

        <Route
          path="/finance/*"
          element={
            <PrivateRouter>
              <FinanceRouter />
            </PrivateRouter>
          }
        />

        <Route
          path="/autoshipping/*"
          element={
            <PrivateRouter>
              <AutoShippingRouter />
            </PrivateRouter>
          }
        />

        <Route
          path="/dealers/*"
          element={
            <PrivateRouter>
              <DealersRouter />
            </PrivateRouter>
          }
        />

        <Route
          path="/commissions/*"
          element={
            <PrivateRouter>
              <CommissionsRouter />
            </PrivateRouter>
          }
        />

        <Route
          path="/products/*"
          element={
            <PrivateRouter>
              <ProductsRouter />
            </PrivateRouter>
          }
        />

        <Route
          path="/email/*"
          element={
            <PrivateRouter>
              <EmailRouter />
            </PrivateRouter>
          }
        />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};
