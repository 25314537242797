import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BasePage from "../BasePage";
import {
  Grid,
  Card,
  IconButton,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  CardHeader,
  Avatar,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { useNavigate } from "react-router-dom";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";
import { getGenealogyDealerService } from "../../APIServices/DealersAPIService";
import { TreeChart } from "../../components/TreeChart";
import { RestorePageOutlined } from "@mui/icons-material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function GenealogyPage(props) {
  const [expanded, setExpanded] = React.useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [dealers, setDealers] = useState(null);
  const [dealerSelected, setDealerSelected] = useState(null);
  const [viewData, setViewData] = useState({
    needData: true,
    loading: Boolean(),
    success: Number(),
  });

  const getGenealogy = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });

    let response = {};
    try {
      response = await getGenealogyDealerService(43096);
      setDealers(response.data);
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loading: Boolean(),
    });

    if (response.code === 0) {
      setDealers(response.data);
    }
  }, []);

  const itemSelectedHandler = (item) => {
    setDealerSelected(item);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (viewData.needData) {
      getGenealogy();
    }
  }, [viewData.needData, getGenealogy]);

  const renderItemTree = (items) => {
    return items.map((item) => {
      return (
        <TreeItem
          key={item.dealerIdentifier}
          nodeId={String(item.dealerIdentifier)}
          label={`${item.dealerIdentifier} - ${item.name}`}
          onClick={() => {
            itemSelectedHandler(item);
          }}
        >
          {renderItemTree(item.childs)}
        </TreeItem>
      );
    });
  };

  const renderCard = (dealerSelected) => {
    if (dealerSelected) {
      return (
        <Card>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "lightgray" }} aria-label="recipe">
                {String(dealerSelected.name)[0]}
              </Avatar>
            }
            // action={
            //   <ExpandMore
            //     expand={expanded}
            //     onClick={handleExpandClick}
            //     aria-expanded={expanded}
            //     aria-label="show more"
            //   >
            //     <ExpandMoreIcon />
            //   </ExpandMore>
            // }
            title={dealerSelected.name}
            subheader={dealerSelected.score}
          />
          <CardContent>
            {/* <Collapse in={expanded} timeout="auto" unmountOnExit> */}
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {dealerSelected.dealerIdentifier}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {`${t("email")}`}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {`Puntos`}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {`${t("commissions")}: ${80}`}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {`${t("calification")}: ${80}`}
              </Typography>
            </CardContent>
            {/* </Collapse> */}
          </CardContent>
        </Card>
      );
    }
  };

  const renderTree = () => {
    let view = null;
    if (dealers) {
      if (dealers.length > 0) {
        view = (
          <Grid container>
            <Grid item xs={12} md={3} sx={{ right: "6vh" }}>
              {renderCard(dealerSelected)}
            </Grid>
            <Grid item xs={12} md={9}>
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ flexGrow: 1, overflowY: "auto" }}
              >
                {renderItemTree(dealers)}
              </TreeView>
            </Grid>
          </Grid>
        );
      }
    } else {
      view = <CircularProgress />;
    }
    return view;
  };

  const renderView = () => {
    let view = null;
    if (dealers) {
      view = <TreeChart data={dealers} />;
    } else {
      view = <CircularProgress />;
    }
    return (
      <Box>
        {/* <TransferDealer /> */}
        {view}
      </Box>
    );
  };

  return (
    <BasePage>
      <PaperBaseComponent>{renderView()}</PaperBaseComponent>
    </BasePage>
  );
}
