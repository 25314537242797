import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  AppBar,
  Toolbar,
  Grid,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import BasePage from "../BasePage";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";
import UIDateSelectComponent from "../../components/General/UIDateSelectComponent";
import { getAutoshippingService } from "../../APIServices/AutoShippingAPIService";
import * as dayjs from "dayjs";
import UIButton from "../../components/General/UIButton";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

//EDITOR
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import SegmentsSelectComponent from "../../components/General/SegmentsSelectComponent";

const label = { inputProps: { "aria-label": "Switch demo" } };

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

export default function SendEmailPage() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [data, setData] = useState({
    scheduleDate: String(),
  });
  const [orders, setOrders] = useState([]);
  const [viewData, setViewData] = useState({
    needData: true,
    loading: Boolean(),
    success: Number(),
  });

  const getOrders = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });

    let response = {};
    try {
      response = await getAutoshippingService();
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loading: Boolean(),
    });
    console.log(`RESPONSE: ${JSON.stringify(response, null, 2)}`);
    if (response.code === 0) {
      setOrders(response.data);
    }
  }, []);

  useEffect(() => {
    if (viewData.needData) {
      //   getOrders();
    }
  }, [viewData.needData, getOrders]);

  const handleSwitchChange = (event) => {
    let scheduleDate = new Date().toISOString();
    if (data.scheduleDate !== String()) {
      scheduleDate = String();
    }
    setData({
      ...data,
      scheduleDate: scheduleDate,
    });
  };

  const renderView = () => {
    return (
      <Stack sx={{ justifyContent: "flex-end" }}>
        <SegmentsSelectComponent
          fullWidth
          margin="normal"
          name="to"
          label={t("to")}
          placeholder={t("to")}
        />
        <TextField
          fullWidth
          margin="normal"
          name="subject"
          label={t("subject")}
          placeholder={t("subject")}
        ></TextField>

        <Typography sx={{ mt: 3 }}>{t("message")}</Typography>
        <ReactQuill
          theme="snow"
          modules={modules}
          placeholder={t("typeTheMessageHere")}
        />

        <Toolbar sx={{ mt: 3, justifyContent: "space-between" }}>
          <Box>
            <FormControlLabel
              labelPlacement="start"
              sx={{ mr: 3, mb: 3, mt: 1 }}
              control={
                <Switch
                  checked={data.scheduleDate !== String()}
                  onChange={handleSwitchChange}
                />
              }
              label={t("schedule")}
            />
            {data.scheduleDate !== String() && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  disablePast
                  defaultValue={dayjs()}
                  value={dayjs(data.scheduleDate)}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value) => {
                    setData({
                      ...data,
                      scheduleDate: value.toISOString(),
                    });
                  }}
                />
              </LocalizationProvider>
            )}
          </Box>
          <Button variant="contained">{"ENVIAR MENSAJE"}</Button>
        </Toolbar>
      </Stack>
    );
  };

  return (
    <BasePage title={t("sendEmail")}>
      <PaperBaseComponent>{renderView()}</PaperBaseComponent>
    </BasePage>
  );
}
