import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SweevenLogo from "../../images/logo_sweeven_light.svg";
import { useTranslation } from "react-i18next";
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  TextField,
  Container,
  CssBaseline,
} from "@mui/material";
import UIButton from "../../components/General/UIButton";
import { signInAction } from "../../actions/AuthenticationAction";

export default function SelectFilePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(Boolean());
  const [data, setData] = useState({
    email: String(),
    password: String(),
  });

  const { email, password } = data;

  const handleTextChange = (e) => {
    const value = e.target.value;

    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(signInAction(email, password));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          sx={{ width: 400, height: "auto", m: 1, mb: 5 }}
          src={SweevenLogo}
        />
        <Typography variant="h3">{t("signIn")}</Typography>
        <Typography variant="h5">{t("admin")}</Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            onChange={handleTextChange}
            value={email}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            type="email"
          />
          <TextField
            onChange={handleTextChange}
            value={password}
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("password")}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}

          <UIButton
            fullWidth
            type="submit"
            variant="contained"
            loading={loading}
            title={t("signIn")}
          />
        </Box>
      </Box>
    </Container>
  );
}
