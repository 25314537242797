import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import { log } from "../../helpers/Logger";
import UISelect from "./UISelect";
import { Countries } from "../../resources/Countries";

export const AddresViewComponent = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(Boolean());
  const [billing, setBilling] = useState(Boolean());
  const [useBillingAddress, setUseBillingAddress] = useState(Boolean());
  const [data, setData] = useState({
    alias: String(),
    firstName: String(),
    lastName: String(),
    address1: String(),
    address2: String(),
    country: String(),
    countryCode: String(),
    city: String(),
    state: String(),
    stateCode: String(),
    zipCode: String(),
  });

  const [countrySelected, setCountrySelected] = useState({
    provinces: [],
  });
  const [needData, setNeedData] = useState(true);

  useImperativeHandle(ref, () => ({
    onClick() {
      document.getElementById("addresForm").submitButton.click();
    },
  }));

  useEffect(() => {
    log("3");
    log(data.countryCode);
    if (process.env.REACT_APP_ENVIRONMENT === "development") {
      setData((prev) => {
        return {
          ...prev,
          alias: "Casa",
          address1: "Calle 1",
          address2: "123",
          city: "MEX",
          zipCode: 12345,
        };
      });
    }

    if (props.address) {
      setData({
        alias: props.address.alias,
        firstName: props.address.firstName,
        lastName: props.address.lastName,
        address1: props.address.address1,
        address2: props.address.address2,
        country: props.address.country,
        countryCode: props.address.countryCode,
        city: props.address.city,
        state: props.address.state,
        stateCode: props.address.stateCode,
        zipCode: props.address.zipCode,
      });
      let country = Countries.filter(
        (country) => country.value === props.address.countryCode
      ).pop();
      setCountrySelected(country);
    }

    if (props.userInfo) {
      setData((prev) => {
        return {
          ...prev,
          firstName: props.userInfo.firstName,
          lastName: props.userInfo.lastName,
        };
      });
    }

    setBilling(props.billing);
    setUseBillingAddress(props.billing);
    setDisabled(props.billing || props.disabled);
  }, [props.disabled, props.address, props.billing, countrySelected]);

  const handleTextChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (props.customKey) {
      props.onComplete("shippingAddress", {
        [props.customKey]: data,
      });
    } else {
      props.onComplete("shippingAddress", data);
    }
  };

  const renderCheck = () => {
    if (billing) {
      return (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={useBillingAddress}
                onChange={() => {
                  setUseBillingAddress(!useBillingAddress);
                  setDisabled(false);
                }}
              />
            }
            label={t("Usar dirección de envio")}
          />
        </Grid>
      );
    }
  };

  return (
    <Box
      sx={{
        mt: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box id="addresForm" component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{props.title}</Typography>
          </Grid>

          {renderCheck()}

          {/*  */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              disabled={disabled}
              name="alias"
              placeholder={"Depa, Casita, etc..."}
              id={`alias-${props.customKey}`}
              label="alias"
              value={data.alias}
              onChange={handleTextChange}
            />
          </Grid>
          {/*  */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              disabled={disabled}
              name="firstName"
              id={`firstName-${props.customKey}`}
              label={t("firstName")}
              value={data.firstName}
              onChange={handleTextChange}
            />
          </Grid>
          {/*  */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              disabled={disabled}
              name="lastName"
              id={`lastName-${props.customKey}`}
              label={t("lastName")}
              value={data.lastName}
              onChange={handleTextChange}
            />
          </Grid>
          {/*  */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              disabled={disabled}
              name="address1"
              id={`address1-${props.customKey}`}
              label={t("address1")}
              value={data.address1}
              onChange={handleTextChange}
            />
          </Grid>
          {/*  */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={disabled}
              name="address2"
              id={`address2-${props.customKey}`}
              label={t("address2")}
              value={data.address2}
              onChange={handleTextChange}
            />
          </Grid>

          {/*  */}
          <Grid item xs={12} sm={6}>
            <UISelect
              required
              fullWidth
              options={Countries}
              disabled={disabled}
              name="country"
              title={t("country")}
              label={t("country")}
              id={`country-${props.customKey}`}
              value={data.countryCode}
              onChange={(item) => {
                let country = Countries.filter(
                  (country) => country.value === item.target.value
                ).pop();
                setCountrySelected(country);
                setData({
                  ...data,
                  countryCode: item.target.value,
                  country: country.name,
                });
              }}
            />
          </Grid>
          {/* STATE */}
          <Grid item xs={12} sm={6}>
            <UISelect
              required
              fullWidth
              options={countrySelected ? countrySelected.provinces : [""]}
              disabled={disabled}
              name="state"
              id={`state-${props.customKey}`}
              title={t("state")}
              label={t("state")}
              value={data.stateCode}
              onChange={(e) => {
                let state = countrySelected.provinces.find(
                  (country) => country.value === e.target.value
                );
                setData({
                  ...data,
                  state: state.name,
                  stateCode: e.target.value,
                });
              }}
            />
          </Grid>
          {/* CITY */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              title={t("city")}
              disabled={disabled}
              name="city"
              id={`city-${props.customKey}`}
              label={t("city")}
              value={data.city}
              onChange={handleTextChange}
            />
          </Grid>
          {/*  */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              disabled={disabled}
              name="zipCode"
              id={`zipCode-${props.customKey}`}
              label={t("zipCode")}
              value={data.zipCode}
              onChange={handleTextChange}
            />
          </Grid>
        </Grid>
        <input type="submit" style={{ display: "none" }} name="submitButton" />
      </Box>
    </Box>
  );
});
