export let RoleType = {
  root: [
    "finances",
    "accounts",
    "accounts/register",
    "dealers",
    "dealers/list",
    "dealers/add",
    "genealogy",
    "autoshipping",
    "products",
    "commissions",
    "emails",
    "funnelGenerator",
    "costumers",
    "reports",
    "tasks",
  ],
  admin: [
    "finances",
    "accounts",
    "accounts/register",
    "dealers",
    "dealers/list",
    "dealers/add",
    "genealogy",
    "autoshipping",
    "products",
    "commissions",
    "emails",
    "funnelGenerator",
    "costumers",
    "reports",
    "tasks",
  ],
};
