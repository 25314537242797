import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import UIButton from "./UIButton";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { log } from "../../helpers/Logger";

export default function DialogModal(props) {
  const { t } = useTranslation();
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    props.onClose();
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={props.loading || Boolean()}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>{props.children}</DialogContent>
        <DialogActions>
          <UIButton
            title={props.cancel ? props.cancel : t("cancel")}
            onClick={handleClose}
          />
          <UIButton
            title={props.accept ? props.accept : t("accept")}
            onClick={props.onAccept}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
