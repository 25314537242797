import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export default function UIDateSelectComponent(props) {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(props.value);

  return (
    // <Stack>
    //   <UISelectComponent
    //     value={props.value}
    //     title={t("month")}
    //     options={months}
    //     onChange={props.onChange}
    //   />
    //   <UISelectComponent
    //     value={props.value}
    //     title={t("month")}
    //     options={months}
    //     onChange={props.onChange}
    //   />
    // </Stack>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={["year", "month"]}
        label="Year and Month"
        minDate={dayjs(new Date(2022, 1, 1))}
        maxDate={dayjs(new Date(2030, 1, 1))}
        value={selectedDate}
        onChange={(date) => {
          setSelectedDate(date);
          console.log(`CHANGED ${date}`);
        }}
        // onClose={(value) => {
        //   console.log(`ON CLOSE ${value}`);
        //   props.onClose(selectedDate);
        // }}
        onAccept={props.onAccept}
        renderInput={(params) => <TextField {...params} helperText={null} />}
      />
    </LocalizationProvider>
  );
}
