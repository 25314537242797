import axios from "axios";
import EndPoints from "../config/EndPoints.json";
import { store } from "../store/store";
import { DealersType } from "../types/DealersType";
import { ViewType } from "../types/ViewType";

export const getUserGeneralInformationAction = () => {
  return new Promise(async (resolve, reject) => {
    const state = store.getState();
    const token = state.auth.token;
    try {
      const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.DealersEndpoint}`;
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response.data.data);
      if (response.data.code === 0) {
        resolve({ code: 0, data: response.data.data });
      }
    } catch (error) {
      console.log(error);
      reject({ code: 0, error: error });
    }
  });
};

export const getGenealogyDealerService = (dealerIdentifier) => {
  return new Promise(async (resolve, reject) => {
    const state = store.getState();
    const token = state.auth.token;
    const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.getDealerGenealogyEndpoint}`;

    try {
      let response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          dealerIdentifier: dealerIdentifier,
        },
      });
      console.log("====================================");
      console.log(response);
      console.log("====================================");
      let result = response.data;
      if (result.code === 0) {
        resolve({ code: 0, data: result.data });
      } else {
        reject({ code: 1 });
      }
    } catch (error) {
      console.log(`ERROR: ${error}`);
      reject({ code: 1, error: error });
    }
  });
};

export const getUserGeneralInformation = () => {
  return async (dispatch) => {
    const state = store.getState();
    const token = state.auth.token;
    try {
      const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.DealersEndpoint}`;
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response.data.data);
      if (response.data.code === 0) {
        dispatch({
          type: DealersType.setDealers,
          payload: response.data.data.map((item) => {
            return {
              ...item,
              id: item.dealerIdentifier,
            };
          }),
        });
      }
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: "",
      //   payload: {},
      // });
      //TODO: ERROR
    }
  };
};
