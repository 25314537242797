import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TextField, Grid } from "@mui/material";
// Import the Slate editor factory.
import { createEditor } from "slate";

//EDITOR
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const INITIAL_FIELDS = {
  title: { value: String(), error: String() },

  descripcionHtml: {
    value: String(),
    error: String(),
  },

  sku: {
    value: String(),
    error: String(),
  },

  confirmEmail: {
    value: String(),
    error: String(),
  },

  password: {
    value: String(),
    error: String(),
  },

  confirmPassword: {
    value: String(),
    error: String(),
  },

  dealerURL: {
    value: String(),
    error: String(),
  },
  phone: {
    value: String(),
    error: String(),
  },

  rfc: {
    value: String(),
    error: String(),
  },
};

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

// const modules = {
//   toolbar: [
//     [{ font: [] }],
//     [{ header: [1, 2, 3, 4, 5, 6, false] }],
//     ["bold", "italic", "underline", "strike"],
//     [{ color: [] }, { background: [] }],
//     [{ script: "sub" }, { script: "super" }],
//     ["blockquote", "code-block"],
//     [{ list: "ordered" }, { list: "bullet" }],
//     ["link", "image", "video"],
//     ["clean"],
//   ],
// };

export default function ProductTemplate(props) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(Boolean());
  const [fields, setFields] = useState(INITIAL_FIELDS);

  // const editor = useMemo(() => withReact(createEditor()), []);
  const [value, setValue] = useState(String());
  //   [
  //   {
  //     children: [{ text: "Testing" }],
  //   },
  // ]

  useEffect(() => {
    if (props.data) {
      let aux = fields;
      Object.keys(props.data).forEach((item) => {
        aux = {
          ...aux,
          [item]: {
            ...fields[item],
            value: props.data[item],
          },
        };
      });
      setFields(aux);
    }
  }, [props.data, fields]);

  const handleTextChange = (e) => {
    const value = e.target.value;
    setFields({
      ...fields,
      [e.target.name]: {
        ...[e.target.name],
        value: value,
      },
    });
  };

  const handleEditorChange = (value) => {
    setValue(value);
  };

  const returnView = () => {
    let view = (
      <Grid container spacing={1}>
        {/* TITLE */}
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={handleTextChange}
            value={fields.title.value}
            margin="normal"
            required
            disabled={disabled}
            fullWidth
            name="title"
            label={t("title")}
            type="text"
            id="title"
          ></TextField>
        </Grid>
        {/* SKU */}
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={handleTextChange}
            value={fields.sku.value}
            margin="normal"
            required
            disabled={disabled}
            fullWidth
            name="sku"
            label={t("sku")}
            type="text"
            id="sku"
          ></TextField>
        </Grid>
        {/* IMAGES */}
        <Grid item xs={12}>
          <TextField name="Images" label={t("images")}></TextField>
        </Grid>

        {/* DESCRIPTION */}
        <Grid item xs={12}>
          <ReactQuill
            theme="snow"
            modules={modules}
            style={{ minHeight: "300px" }}
          />
          {/* <TextEditorComponent /> */}
          {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
          {/* <TextField
            onChange={handleTextChange}
            value={fields.sku.value}
            margin="normal"
            required
            disabled={disabled}
            fullWidth
            name="sku"
            label={t("sku")}
            type="text"
            id="sku"
          ></TextField> */}
        </Grid>
        {/*  // PRICE // SKU // VARIANTS // VOLUME */}
      </Grid>
    );

    return view;
  };

  return returnView();
}
