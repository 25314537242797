import { UserTypes } from "../types/UserTypes";

export const updateInfo = (data) => {
  return {
    type: UserTypes.updateInfo,
    payload: {
      data,
    },
  };
};
