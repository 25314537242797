import { AuthTypes } from "../types/AuthTypes";
import {
  auth,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  signOut,
} from "../firebase/firebaseConfig";

export const login = (id, user, token) => {
  return {
    type: AuthTypes.signIn,
    payload: {
      id,
      user,
      token,
    },
  };
};

export const signInAction = (email, password) => {
  return (dispatch) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
      })
      .catch((error) => {});
  };
};

export const signUpAction = (name, lastName, email, password) => {
  return (dispatch) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        updateProfile(auth.currentUser, {
          displayName: `${name} ${lastName}`,
        }).then(() => {
          dispatch({
            type: AuthTypes.nameChanged,
            payload: `${name} ${lastName}`,
          });
          //addUSerInDataBase(user.uid);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const signOutAction = () => {
  return (dispatch) => {
    signOut(auth).then(() => {
      dispatch({
        type: AuthTypes.signOut,
      });
    });
  };
};
