import { DealersType } from "../types/DealersType";

let INITIAL_STATE = {
  dealerSelected: {
    firstName: String(),
    lastName: String(),
    email: String(),
    dealerURL: String(),
    phone: { code: String(), number: String() },
    rfc: String(),
    dealerIdentifier: String(),
    sponsorIdentifier: String(),
    sponsorName: String(),
    commissionPoints: Number(),
    commissionPending: Number(),
    calificationPoints: Number(),
    calificationPending: Number(),
  },
};

export const DealersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DealersType.setDealers:
      return {
        ...state,
        dealers: action.payload,
      };

    case DealersType.setDealer:
      return {
        ...state,
        dealerSelected: state.dealers.filter(
          (dealer) => dealer.dealerIdentifier === Number(action.payload)
        )[0],
      };

    default:
      return state;
  }
};
