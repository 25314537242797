import { AuthTypes } from "../types/AuthTypes";

export const AuthReducer = (state = {}, action) => {
  switch (action.type) {
    case AuthTypes.signIn:
      return action.payload;

    case AuthTypes.signOut:
      return {};

    case AuthTypes.nameChanged:
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
};
