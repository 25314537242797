import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper,
  AppBar,
  Toolbar,
  Grid,
  TextField,
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import BasePage from "../BasePage";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";
import UIButton from "../../components/General/UIButton";
import UIStepper from "../../components/General/UIStepper";
import { getAutoshippingService } from "../../APIServices/AutoShippingAPIService";
import * as dayjs from "dayjs";

export default function AddDealerPage(props) {
  const { t } = useTranslation();
  const [data, setData] = useState({
    enrollDate: new Date(),
  });

  const handleChangeText = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const renderView = () => {
    return (
      <Grid container m={1} spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="firstName"
            id="firstName"
            type="firstName"
            value={data.firstName}
            label={t("firstName")}
            onChange={handleChangeText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="lastName"
            id="lastName"
            type="lastName"
            value={data.lastName}
            label={t("lastName")}
            onChange={handleChangeText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="email"
            id="email"
            type="email"
            value={data.email}
            label={t("email")}
            onChange={handleChangeText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="phone"
            id="phone"
            type="phone"
            value={data.phone}
            label={t("phone")}
            onChange={handleChangeText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="dealerURL"
            id="dealerURL"
            type="dealerURL"
            value={data.dealerURL}
            label={t("dealerURL")}
            onChange={handleChangeText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="companyName"
            id="companyName"
            type="text"
            value={data.companyName}
            label={t("companyName")}
            onChange={handleChangeText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="password"
            id="password"
            type="password"
            value={data.password}
            label={t("password")}
            onChange={handleChangeText}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="confirmPassword"
            id="confirmPassword"
            type="password"
            value={data.confirmPassword}
            label={t("confirmPassword")}
            onChange={handleChangeText}
          ></TextField>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="birthdate"
            id="birthdate"
            type="date"
            value={data.birthdate}
            label={t("birthdate")}
            onChange={handleChangeText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="rfc"
            id="rfc"
            type="text"
            value={data.rfc}
            label={t("rfc")}
            onChange={handleChangeText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="sponsorIdentifier"
            id="sponsorIdentifier"
            type="number"
            value={data.sponsorIdentifier}
            label={t("sponsorIdentifier")}
            onChange={handleChangeText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="enrollDate"
            id="enrollDate"
            type="date"
            value={data.enrollDate}
            label={t("enrollDate")}
            onChange={handleChangeText}
          />
        </Grid>
        <Grid item xs={12}>
          <UIButton fullWidth variant="contained" title={t("createDealer")} />
        </Grid>
      </Grid>
    );
  };

  return (
    <BasePage title={t("addDealer")}>
      <PaperBaseComponent>{renderView()}</PaperBaseComponent>
    </BasePage>
  );
}
