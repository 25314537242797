import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import BasePage from "../../pages/BasePage";
import {
  Grid,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Stack,
} from "@mui/material";
import UIButton from "../../components/General/UIButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AddresViewComponent } from "../../components/General/AddresViewComponent";
import { useLocation, useNavigate } from "react-router-dom";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";
import {
  deleteAutoshippingService,
  acceptAutoshippingService,
  pauseAutoshippingService,
} from "../../APIServices/AutoShippingAPIService";

export default function AutoShippingDetailPage(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigation = useNavigate();
  const order = location.state ? location.state.data : {};
  const [localAuth, setLocalAuth] = useState(false);
  const [viewData, setViewData] = useState({
    needData: true,
    loadingDelete: Boolean(),
    acceptLoading: Boolean(),
    loading: Boolean(),
    success: Number(),
  });

  // const navigate = useNavigate();
  const [data, setData] = useState({
    contact: {},
    products: {},
    interval: {},
    shippingAddress: {},
    billingAddress: {},
  });

  const pauseAutoshippingInvoke = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });

    let response = {};
    try {
      response = await pauseAutoshippingService(order.identifier);
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loading: Boolean(),
    });
    console.log(`RESPONSE: ${JSON.stringify(response, null, 2)}`);
    if (response.code === 0) {
      alert("Se ha pausado el autoenvío");
      navigation("/autoshipping");
    }
  }, [order.identifier, navigation]);

  const deleteAutoshippingInvoke = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loadingDelete: true,
    });

    let response = {};
    try {
      response = await deleteAutoshippingService(order.identifier);
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loadingDelete: Boolean(),
    });
    console.log(`RESPONSE: ${JSON.stringify(response, null, 2)}`);
    if (response.code === 0) {
      alert("Se eliminado el autoenvío");
      navigation(-1);
    }
  }, [order.identifier, navigation]);

  const acceptAutoshippingInvoke = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      acceptLoading: true,
    });

    let response = {};
    try {
      response = await acceptAutoshippingService(order.identifier);
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loadingDelete: Boolean(),
    });
    console.log(`RESPONSE: ${JSON.stringify(response, null, 2)}`);
    if (response.code === 0) {
      alert("Se ha aceptado el autoenvio");
      navigation(-1);
    }
  }, [order.identifier, navigation]);

  const totalOrderView = () => {
    let totalPrice = order.products.reduce(
      (total, product) => total + product.distributorPrice * product.quantity,
      0
    );

    console.log(totalPrice);
    let totalPoints = order.products.reduce(
      (total, product) => total + product.calificationPoints * product.quantity,
      0
    );
    return (
      <Grid container spacing={2} m={2}>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>{`${t(
            "totalImport"
          )}: `}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${Intl.NumberFormat("es-MX").format(
            totalPrice
          )} MXN`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>{`${t(
            "ascendet"
          )}: `}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${totalPoints} PTS`}</Typography>
        </Grid>
      </Grid>
    );
  };

  const totalInformationView = () => {
    return (
      <Grid container spacing={2} m={2}>
        <Grid item xs={6}>
          <Typography
            sx={{ textAlign: "right", fontWeight: "bold" }}
          >{`Email:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{order.email}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>{`${t(
            "phone"
          )}: `}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{order.phone}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasePage>
      <PaperBaseComponent sx={{ m: 2 }}>
        <Grid container spacing={2} m={2}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <IconButton
                aria-label="back"
                onClick={() => {
                  navigation(-1);
                }}
              >
                <ArrowBackIcon
                  sx={{
                    fontSize: 32,
                  }}
                />
              </IconButton>
              <Typography
                variant="h5"
                sx={{ display: "flex", alignItems: "center" }}
              >
                {t("order")}
              </Typography>
            </Stack>

            <Divider></Divider>
          </Grid>

          <Grid item xs={12}>
            {totalInformationView()}
          </Grid>

          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {"SKU"}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {t("name")}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {t("price")}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {t("calification")}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {t("quantity")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.products.map((product) => (
                    <TableRow
                      key={product.sku}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {product.sku}
                      </TableCell>
                      <TableCell align="center">{product.name}</TableCell>
                      <TableCell align="center">{`${Intl.NumberFormat(
                        "es-MX"
                      ).format(product.distributorPrice)} MXN`}</TableCell>
                      <TableCell align="center">
                        {product.calificationPoints}
                      </TableCell>
                      <TableCell align="center">
                        <Typography>{product.quantity}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            {totalOrderView()}
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Typography sx={{ fontWeight: "bold" }}>
                {`Fecha de inicio:`}
              </Typography>
              <Typography>{`${order.interval.startedDate}`}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={1}>
              <Typography sx={{ fontWeight: "bold" }}>
                {`Envio cada:`}
              </Typography>
              <Typography>{`${t(order.interval.receive)}`}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Typography sx={{ fontWeight: "bold" }}>
                {`${t("status")}:`}
              </Typography>
              <Typography>{`${t(order.status)}`}</Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: 42 }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5">{t("shippingAddress")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AddresViewComponent
                  address={order.shippingAddress}
                  disabled={true}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: 42 }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5">{t("billingAddress")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AddresViewComponent
                  address={order.billingAddress}
                  disabled={true}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            <UIButton
              fullWidth
              title={t("accept")}
              loading={viewData.acceptLoading}
              variant="outlined"
              onClick={() => {
                acceptAutoshippingInvoke();
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() =>
                navigation("/autoshipping/update", { state: { data: order } })
              }
            >
              {t("modify")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              onClick={pauseAutoshippingInvoke}
            >
              {t("pause")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <UIButton
              fullWidth
              title={t("delete")}
              loading={viewData.loadingDelete}
              color="error"
              variant="outlined"
              onClick={() => {
                deleteAutoshippingInvoke();
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="contained">
              {t("back")}
            </Button>
          </Grid>
        </Grid>
      </PaperBaseComponent>
    </BasePage>
  );
}
