import "./App.css";
import React, { Suspense, useEffect } from "react";
import { useMediaQuery, CssBaseline } from "@mui/material";
import { Provider, useSelector } from "react-redux";
import { AppRouter } from "./routers/AppRouter";
import { SnackbarProvider } from "notistack";
import ErrorBoundary from "./helpers/ErrorBoundary";
import { store } from "./store/store";
import { ThemeProvider } from "@mui/material/styles";
import { getTheme } from "./ThemeConfig";
import { AnimatePresence } from "framer-motion";

import { log } from "./helpers/Logger";

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {
  //const appConfig = useSelector((state) => state.appConfig);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  // let theme = themeConfig;
  // theme = {
  //   ...theme,
  //   palette: { ...theme.palette, mode: prefersDarkMode ? "dark" : "light" },
  // };

  const [mode, setMode] = React.useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(() => getTheme(mode), [mode]);

  return (
    <ErrorBoundary>
      <Suspense fallback="... LOADIN???">
        <Provider store={store}>
          <SnackbarProvider preventDuplicate maxSnack={6}>
            <ColorModeContext.Provider value={colorMode}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <AnimatePresence>
                  <AppRouter />
                </AnimatePresence>
              </ThemeProvider>
            </ColorModeContext.Provider>
          </SnackbarProvider>
        </Provider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
