import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth, onAuthStateChanged } from "../firebase/firebaseConfig";
import PageLoadingComponent from "../components/General/PageLoadingComponent";
import { getUserService } from "../APIServices/UserAPIService";
import {
  login,
  getUserGeneralInformationAction,
} from "../actions/AuthenticationAction";
import { updateInfo } from "../actions/UserActions";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";

const PrivateRouter = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logged, setLogged] = useState(null);
  const [user, setUser] = useState(null);
  const register = useSelector((state) => state.auth.registered);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log(user);
        let response = await getUserService(user.uid);
        if (response.code === 0) {
          dispatch(updateInfo(response.data));
        }

        setLogged(true);
        setUser(user);
        dispatch(login(user.uid, user.displayName, user.accessToken));
      } else {
        setUser(null);
        setLogged(Boolean());
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (register) {
      navigate("/");
    }
  }, [register, navigate]);
  console.log(location.pathname);
  if (logged != null || logged) {
    if (user == null) {
      return <Navigate to="/signin" replace />;
    }

    return children;
  } else {
    return <PageLoadingComponent />;
  }
};

export default PrivateRouter;
