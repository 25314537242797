import { combineReducers, compose, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { AuthReducer } from "../reducers/AuthReducer";
import { DealersReducer } from "../reducers/DealersReducer";
import { UserReducer } from "../reducers/UserReducer";
import { CommissionsReducer } from "../reducers/CommissionsReducer";
import { AppConfigReducer } from "../reducers/AppConfigReducer";

const reducers = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  dealers: DealersReducer,
  commissionsState: CommissionsReducer,
  appConfig: AppConfigReducer,
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export const store = configureStore(
  { reducer: reducers },
  composeEnhancers(applyMiddleware(thunk))
);
