// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  addDoc,
  onSnapshot,
  query,
  updateDoc,
  getDocs,
  writeBatch,
  orderBy,
  where,
  deleteDoc,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInAnonymously,
  signOut,
  updateProfile,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA-NZv5C-G8LZ3HeJocrIzeb8Yk9Iox2uA",
  authDomain: "life-sweeven-autoshipping.firebaseapp.com",
  projectId: "life-sweeven-autoshipping",
  storageBucket: "life-sweeven-autoshipping.appspot.com",
  messagingSenderId: "917762655058",
  appId: "1:917762655058:web:4f993a5dde99bdb6c8708b",
  measurementId: "G-9R9NJLJXCX",
};

const clientApp = initializeApp(firebaseConfig, "secondary");
const db = getFirestore(clientApp);
const auth = getAuth(clientApp);

export {
  clientApp,
  db,
  doc,
  auth,
  writeBatch,
  query,
  getDocs,
  deleteDoc,
  orderBy,
  where,
  setDoc,
  collection,
  addDoc,
  updateDoc,
  onSnapshot,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
  signInAnonymously,
};
