const log = (message) => {
  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    console.log("====================================");
    console.log(message);
    console.log("====================================");
  }
};

const logError = (message) => {
  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    console.log("====================================");
    console.log(message);
    console.log("====================================");
  }
};

module.exports = { log, logError };
