export let Countries = [
  {
    value: "MX",
    name: "Mexico",
    provinces: [
      {
        value: "AGS",
        name: "Aguascalientes",
      },
      {
        value: "BC",
        name: "Baja California",
      },
      {
        value: "BCS",
        name: "Baja California Sur",
      },
      {
        value: "CAMP",
        name: "Campeche",
      },
      {
        value: "CHIS",
        name: "Chiapas",
      },
      {
        value: "CHIH",
        name: "Chihuahua",
      },
      {
        value: "DF",
        name: "Ciudad de México",
      },
      {
        value: "COAH",
        name: "Coahuila",
      },
      {
        value: "COL",
        name: "Colima",
      },
      {
        value: "DGO",
        name: "Durango",
      },
      {
        value: "GTO",
        name: "Guanajuato",
      },
      {
        value: "GRO",
        name: "Guerrero",
      },
      {
        value: "HGO",
        name: "Hidalgo",
      },
      {
        value: "JAL",
        name: "Jalisco",
      },
      {
        value: "MEX",
        name: "México",
      },
      {
        value: "MICH",
        name: "Michoacán",
      },
      {
        value: "MOR",
        name: "Morelos",
      },
      {
        value: "NAY",
        name: "Nayarit",
      },
      {
        value: "NL",
        name: "Nuevo León",
      },
      {
        value: "OAX",
        name: "Oaxaca",
      },
      {
        value: "PUE",
        name: "Puebla",
      },
      {
        value: "QRO",
        name: "Querétaro",
      },
      {
        value: "Q ROO",
        name: "Quintana Roo",
      },
      {
        value: "SLP",
        name: "San Luis Potosí",
      },
      {
        value: "SIN",
        name: "Sinaloa",
      },
      {
        value: "SON",
        name: "Sonora",
      },
      {
        value: "TAB",
        name: "Tabasco",
      },
      {
        value: "TAMPS",
        name: "Tamaulipas",
      },
      {
        value: "TLAX",
        name: "Tlaxcala",
      },
      {
        value: "VER",
        name: "Veracruz",
      },
      {
        value: "YUC",
        name: "Yucatán",
      },
      {
        value: "ZAC",
        name: "Zacatecas",
      },
    ],
  },
];
