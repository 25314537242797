import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Toolbar,
  Grid,
  Button,
  AppBar,
  Tooltip,
  IconButton,
  ScopedCssBaseline,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import BasePage from "../BasePage";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";
import { getUserGeneralInformation } from "../../APIServices/DealersAPIService";
import { log } from "../../helpers/Logger";
import { createCSVFile } from "../../helpers/CSVFIle";
import { DealersType } from "../../types/DealersType";
import { makeStyles } from "@mui/styles";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "firstName", headerName: "First name", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
  {
    field: "dealerURL",
    headerName: "Dealer URL",
    width: 120,
    valueGetter: (params) => `${params.row.dealerURL}`,
  },
  { field: "email", headerName: "Email", width: 250 },
  { field: "currentRange", headerName: "Rango", width: 100 },
  { field: "commissionPoints", headerName: "Comision", width: 100 },
  {
    field: "calificationPoints",
    headerName: "Calificación",
    width: 100,
  },
  {
    field: "gvp",
    headerName: "GVP",
    width: 100,
  },
];
const useStyles = makeStyles({
  myCustomStyles: {
    border: "1px solid red",
  },
});

export default function ListDealerPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dealers_ = useSelector((state) => state.dealers.dealers);

  // const getData = useCallback(async () => {
  //   setViewData({
  //     needData: Boolean(),
  //     loading: true,
  //   });

  //   let response = {};
  //   try {
  //     response = await getUserGeneralInformationAction();
  //   } catch (error) {}

  //   setViewData({
  //     needData: Boolean(),
  //     loading: Boolean(),
  //   });
  //   console.log(`RESPONSE: ${JSON.stringify(response, null, 2)}`);
  //   if (response.code === 0) {
  //     setDealers(
  //       response.data.map((item) => {
  //         return {
  //           ...item,
  //           id: item.dealerIdentifier,
  //         };
  //       })
  //     );
  //   }
  // }, []);

  useEffect(() => {
    if (dealers_ === undefined || dealers_ === null) {
      dispatch(getUserGeneralInformation());
    }
  }, [dispatch, dealers_]);

  const handleExportData = () => {
    if (dealers_) {
      let csv = createCSVFile(dealers_);
      const blob = new Blob([csv], { type: "data:text/csv;charset=utf-8," });
      // Crear un enlace de descarga para el archivo CSV
      const enlace = document.createElement("a");
      enlace.href = URL.createObjectURL(blob);
      enlace.target = "_blank";
      enlace.download = "thear" + ".csv";

      // Simular el clic en el enlace para descargar el archivo
      enlace.style.display = "none";
      document.body.appendChild(enlace);
      enlace.click();
      document.body.removeChild(enlace);
    }
  };

  const myClasses = useStyles();

  const renderView = () => {
    let view = null;
    if (dealers_) {
      if (dealers_.length > 0) {
        view = (
          <Box sx={{}}>
            <AppBar
              position="static"
              color="default"
              elevation={0}
              sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs />
                  <Grid item>
                    <Button
                      variant="outlined"
                      sx={{ mr: 1 }}
                      onClick={handleExportData}
                    >
                      {t("exportData")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" sx={{ mr: 1 }}>
                      {t("addClient")}
                    </Button>
                    <Tooltip title="Reload">
                      <IconButton>
                        <RefreshIcon
                          color="inherit"
                          sx={{ display: "block" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  height: "66vh",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <React.Fragment>
                  <ScopedCssBaseline>
                    {/* The rest of your application */}
                    <div style={{ height: "66vh" }}>
                      <DataGrid
                        // classes={{
                        //   root: myClasses.myCustomStyles,
                        // }}
                        // sx={{
                        //   "& .MuiDataGrid-columnHeaderTitle": {
                        //     whiteSpace: "normal",
                        //     lineHeight: "normal",
                        //   },
                        //   "& .MuiDataGrid-columnHeader": {
                        //     // Forced to use important since overriding inline styles
                        //     height: "unset !important",
                        //   },
                        //   "& .MuiDataGrid-columnHeaders": {
                        //     // Forced to use important since overriding inline styles
                        //     maxHeight: "168px !important",
                        //   },
                        // }}

                        columnHeaderHeight={56}
                        rows={dealers_}
                        columns={columns}
                        // initialState={{
                        //   pagination: {
                        //     paginationModel: {
                        //       pageSize: 10,
                        //     },
                        //   },
                        // }}
                        // rowsPerPageOptions={[10]}
                        checkboxSelection={false}
                        onRowClick={(data) => {
                          let dealer = dealers_.find(
                            (i) => i.dealerIdentifier === data.id
                          );
                          if (dealer) {
                            dispatch({
                              type: DealersType.setDealer,
                              payload: dealer.dealerIdentifier,
                            });
                            navigate("/dealers/detail");
                          }
                        }}
                      />
                    </div>
                  </ScopedCssBaseline>
                </React.Fragment>
              </Box>
            </Box>
          </Box>
        );
      }
    } else {
      view = <CircularProgress />;
    }
    return view;
  };

  return (
    <BasePage title={t("dealers")}>
      <PaperBaseComponent>{renderView()}</PaperBaseComponent>
    </BasePage>
  );
}
