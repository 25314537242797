import React, { useState } from "react";
import { CssBaseline, Typography, Link } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Navigator from "../components/Navigator";
import Header from "../components/Header";
import { getTheme } from "../ThemeConfig";

const drawerWidth = 256;

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://sweeven.life">
        {"Sweeven IT "}
      </Link>
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function BasePage(props) {
  //   const [content] = props.content;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(getTheme(Boolean()).breakpoints.up("sm"));

  const [alertOption, setAlertOption] = useState({
    open: false,
    duration: 3000,
    severity: "success",
    message: "THIS IS A MESSAGE",
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <CssBaseline />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {isSmUp ? null : (
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        )}

        <Navigator
          PaperProps={{ style: { width: drawerWidth } }}
          sx={{ display: { sm: "block", xs: "none" } }}
        />
      </Box>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Header
          onDrawerToggle={handleDrawerToggle}
          title={props.title ?? "????"}
        />
        <Box
          component="main"
          sx={{
            flex: 1,
            py: 6,
            px: 4,
            bgcolor: "background.default",
            overflow: "auto",
          }}
        >
          {props.children}
        </Box>
        <Box component="footer" sx={{ p: 2 }}>
          <Copyright />
        </Box>
      </Box>
    </Box>
  );
}
