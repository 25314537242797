import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  AppBar,
  Toolbar,
  Grid,
  TextField,
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import BasePage from "../BasePage";
import { useTranslation } from "react-i18next";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";
import UIDateSelectComponent from "../../components/General/UIDateSelectComponent";
import * as dayjs from "dayjs";

export default function ValidatorPage() {
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [needData, setNeedData] = useState(true);
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const [products, setProducts] = useState([]);

  let route = "http://localhost:4000/finance/validator?";

  const getOrders = useCallback(async () => {
    fetch(
      route +
        new URLSearchParams({
          month: Number(selectedDate.month()),
          year: Number(selectedDate.year()),
        }),
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setNeedData(Boolean());
        setProducts(result.data);
        console.log(result.data);
      })
      .catch((error) => {
        console.log("ERROR");
        console.log(error);
        setNeedData(Boolean());
      });
  }, [route, selectedDate]);

  useEffect(() => {
    console.log("1");
    if (needData) {
      getOrders();
    }
  }, [needData, getOrders]);

  return (
    <BasePage title={t("validator")}>
      <PaperBaseComponent>
        <AppBar
          position="static"
          color="default"
          align="center"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Toolbar>
            <Grid container spacing={0} alignItems="center">
              <Grid item>
                <SearchIcon color="inherit" sx={{ display: "block" }} />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by email address, phone number, or user UID"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: "default" },
                  }}
                  variant="standard"
                />
              </Grid>
              <Grid item>
                <Tooltip title="Reload">
                  <IconButton>
                    <RefreshIcon color="inherit" sx={{ display: "block" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
          <Stack>
            <UIDateSelectComponent
              value={selectedDate}
              onAccept={(date) => {
                console.log(`MONTH: ${date.month()}`);
                setSelectedDate(date);
                setNeedData(true);
              }}
            />
          </Stack>
        </AppBar>
        {products.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t("SKU")}</TableCell>
                  <TableCell align="right">{t("concept")}</TableCell>
                  <TableCell align="right">{t("sumConvertion")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow
                    key={product.lineitemSku}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" component="th" scope="row">
                      {product.lineitemSku}
                    </TableCell>
                    <TableCell align="right">{product.count}</TableCell>
                    <TableCell align="right">
                      {Intl.NumberFormat("es-MX").format(product.amount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <CircularProgress />
        )}
      </PaperBaseComponent>
    </BasePage>
  );
}
