import React, { useEffect, useState } from "react";
import { auth, onAuthStateChanged } from "../firebase/firebaseConfig";
import PageLoadingComponent from "../components/General/PageLoadingComponent";
import { Navigate } from "react-router-dom";

const PublicRouter = ({ children }) => {
  const [logged, setLogged] = useState(null);
  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLogged(true);
        setUser(user);
      } else {
        setLogged(Boolean());
      }
    });
  });

  if (logged != null) {
    if (user) {
      return <Navigate to="/" replace />;
    }

    return children;
  } else {
    return <PageLoadingComponent />;
  }
};

export default PublicRouter;
