import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AddDealerPage from "../pages/Dealers/AddDealerPage";
import DetailDealerPage from "../pages/Dealers/DetailDealerPage";
import ListDealerPage from "../pages/Dealers/ListDealerPage";
import GenealogyPage from "../pages/Dealers/GenealogyPage";

const DealersRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ListDealerPage />} />
      <Route exact path="/add" element={<AddDealerPage />} />
      <Route exact path="/detail" element={<DetailDealerPage />} />
      <Route exact path="/genealogy" element={<GenealogyPage />} />
      <Route path="*" element={<ListDealerPage />} />
      {/* <Route path="*" element={<ListDealerPage />} /> */}
    </Routes>
  );
};

export default DealersRouter;
