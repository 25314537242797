import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Box } from "@mui/material";
import DealerUrlComponent from "../Enroll/DealerUrlComponent";
import { log } from "../../helpers/Logger";
import UIButton from "./UIButton";
import { DealersType } from "../../types/DealersType";
//var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
var regex = new RegExp("^(?=.{8,})");
// const rfcRegex = new RegExp(
//   "/^([A-ZÑ&]{3,4}) ?(?:- ?)?(d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]d|3[01])) ?(?:- ?)?([A-Zd]{2})([Ad])$/"
// );

export const UserDataComponent = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(Boolean());
  const [editing, setEditing] = useState(Boolean());
  const [data, setData] = useState({
    firstName: String(),
    lastName: String(),
    email: String(),
    confirmEmail: String(),
    dealerURL: String(),
    phone: String(),
    rfc: String(),
    dealerIdentifier: String(),
    sponsorIdentifier: String(),
    sponsorName: String(),
  });

  const [urlValid, setUrlValid] = useState(Boolean());
  const INITIAL_FIELDS = {
    firstName: { value: "", error: String() },

    lastName: {
      value: "",
      error: String(),
    },

    email: {
      value: "",
      error: String(),
    },

    confirmEmail: {
      value: "",
      error: String(),
    },

    password: {
      value: "",
      error: String(),
    },

    confirmPassword: {
      value: "",
      error: String(),
    },

    dealerURL: {
      value: "",
      error: String(),
    },
    phone: {
      value: "",
      error: String(),
    },

    rfc: {
      value: "",
      error: String(),
    },
  };
  const [fields, setField] = useState(INITIAL_FIELDS);

  useImperativeHandle(ref, () => ({
    onClick() {
      document.getElementById("userForm").submitButton.click();
    },
  }));

  useEffect(() => {
    if (props.disabled) {
      setDisabled(props.disabled);
    }

    if (props.editing) {
      setEditing(props.editing);
    }

    if (props.user) {
      setData({
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        email: props.user.email,
        confirmEmail: props.user.confirmEmail,
        dealerURL: props.user.dealerURL,
        phone: props.user.phone,
        rfc: props.user.rfc,
        dealerIdentifier: props.user.dealerIdentifier,
        sponsorIdentifier: props.user.sponsorIdentifier,
        sponsorName: props.user.sponsorName,
      });
    } else if (process.env.REACT_APP_ENVIRONMENT === "development") {
      setData({
        firstName: "SWEEVEN USER",
        lastName: "LASTNAME",
        email: "it_14@sweeven.life",
        confirmEmail: "it_14@sweeven.life",
        password: "Abcd1234",
        confirmPassword: "Abcd1234",
        dealerURL: "URL93",
        phone: "5515555518",
        rfc: "12312312DFG12",
      });
    }
  }, [props]);

  const handleTextChange = (e) => {
    const value = e.target.value;
    // if (e.target.name === "password" || e.target.name === "confirmPassword") {
    //   value = crypt(value);
    // }
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (data.email !== data.confirmEmail) {
      setField((prev) => {
        return {
          ...prev,
          email: {
            ...prev.email,
            error: t("mustBeSameEmail"),
          },
          confirmEmail: {
            ...prev.confirmEmail,
            error: t("mustBeSameEmail"),
          },
        };
      });
      return;
    }

    if (data.password === data.confirmPassword) {
      if (!regex.test(data.password)) {
        setField((prev) => {
          return {
            ...prev,
            password: {
              ...prev.password,
              error: t("minimumcharacters"),
            },
          };
        });
        return;
      }
    } else {
      setField((prev) => {
        return {
          ...prev,
          password: {
            ...prev.password,
            error: t("mustBeSamePassword"),
          },
          confirmPassword: {
            ...prev.confirmPassword,
            error: t("mustBeSamePassword"),
          },
        };
      });
      return;
    }

    if (!urlValid) {
      setField((prev) => {
        return {
          ...prev,
          dealerURL: {
            ...prev.dealerURL,
            error: "URL NO VALIDA",
          },
        };
      });
      return;
    }

    if (data.rfc.length !== 13 && data.rfc.length !== 10) {
      setField((prev) => {
        return {
          ...prev,
          rfc: {
            ...prev.rfc,
            error: t("notValid"),
          },
        };
      });
      return;
    }

    props.onComplete("Datos generales", data);
  };

  return (
    <Box
      sx={{
        mt: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        id="userForm"
        component="form"
        onSubmit={handleSubmit}
        sx={{ mt: 1 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              value={data.firstName}
              margin="normal"
              required
              disabled={disabled || !editing}
              fullWidth
              name="firstName"
              label={t("firstName")}
              type="firstName"
              id="firstName"
              autoComplete="firstName"
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          {/*  */}
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              disabled={disabled || !editing}
              value={data.lastName}
              margin="normal"
              required
              fullWidth
              name="lastName"
              label={t("lastName")}
              type="lastName"
              id="lastName"
              autoComplete="lastName"
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          {/*  */}
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              disabled={true}
              value={data.email}
              error={fields.email.error !== String()}
              helperText={fields.email.error}
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              autoComplete="email"
              label={t("email")}
              name="email"
            />
          </Grid>

          {/*  */}
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              disabled={true}
              value={data.dealerURL}
              margin="normal"
              required
              fullWidth
              name="dealerURL"
              label={"sweeven.live/"}
              type="text"
              id="dealerURL"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              disabled={disabled || !editing}
              value={
                data.phone ? data.phone.code + data.phone.number : String()
              }
              margin="normal"
              required
              fullWidth
              name="phone"
              label={t("phone")}
              type="phone"
              id="phone"
              autoComplete="phone"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              disabled={disabled || !editing}
              value={data.rfc}
              error={fields.rfc.error !== String()}
              helperText={fields.rfc.error}
              margin="normal"
              required
              fullWidth
              name="rfc"
              label={t("rfc")}
              type="text"
              id="rfc"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              disabled={disabled || !editing}
              value={data.rfc}
              error={fields.rfc.error !== String()}
              helperText={fields.rfc.error}
              margin="normal"
              required
              fullWidth
              name="rfc"
              label={t("rfc")}
              type="text"
              id="rfc"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              disabled={true}
              value={data.dealerIdentifier}
              margin="normal"
              required
              fullWidth
              name="dealerIdentifier"
              label={t("dealerIdentifier")}
              type="text"
              id="dealerIdentifier"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              onChange={handleTextChange}
              disabled={true}
              value={data.sponsorName}
              margin="normal"
              required
              fullWidth
              name="sponsorName"
              label={t("sponsorName")}
              type="text"
              id="sponsorName"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              onChange={handleTextChange}
              disabled={true}
              value={data.sponsorIdentifier}
              margin="normal"
              required
              fullWidth
              name="sponsorIdentifier"
              label={t("sponsorIdentifier")}
              type="text"
              id="sponsorIdentifier"
            />
          </Grid>

          <Grid item xs={12} sm={4} sx={{ m: "auto" }}>
            <UIButton
              fullWidth
              title={t("goToDealer")}
              onClick={() => {
                props.handleGoToDealer();
                dispatch({
                  type: DealersType.setDealer,
                  payload: data.sponsorIdentifier,
                });
              }}
            />
          </Grid>
        </Grid>

        <input type="submit" style={{ display: "none" }} name="submitButton" />
      </Box>
    </Box>
  );
});
