import * as React from "react";
import { LoadingButton } from "@mui/lab";

export default function UIButton(props) {
  return (
    <LoadingButton
      {...props}
      value={props.value}
      name={props.name}
      sx={props.sx ?? { mt: 2, mb: 1 }}
      id={props.id ?? String()}
      fullWidth={props.fullWidth}
      type={props.type ?? "button"}
      loading={props.loading}
      variant={props.variant ?? "outlined"}
      onClick={props.onClick}
    >
      {props.title}
      {props.children}
    </LoadingButton>
  );
}
