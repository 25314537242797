import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper,
  AppBar,
  Toolbar,
  Grid,
  TextField,
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import BasePage from "../BasePage";
import PaperBaseComponent from "../../components/General/PaperBaseComponent";
import UIDateSelectComponent from "../../components/General/UIDateSelectComponent";
import { getAutoshippingService } from "../../APIServices/AutoShippingAPIService";
import * as dayjs from "dayjs";

export default function AutoShippingListPage() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [orders, setOrders] = useState([]);
  const [viewData, setViewData] = useState({
    needData: true,
    loading: Boolean(),
    success: Number(),
  });

  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const getOrders = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });

    let response = {};
    try {
      response = await getAutoshippingService();
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loading: Boolean(),
    });
    console.log(`RESPONSE: ${JSON.stringify(response, null, 2)}`);
    if (response.code === 0) {
      setOrders(response.data);
    }
  }, []);

  useEffect(() => {
    if (viewData.needData) {
      getOrders();
    }
  }, [viewData.needData, getOrders]);

  const renderView = () => {
    return orders.length > 0 ? (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{t("email")}</TableCell>
              <TableCell align="right">{t("state")}</TableCell>
              <TableCell align="right">{t("mofifiedAt")}</TableCell>
              <TableCell align="right">{t("date")}</TableCell>
              <TableCell align="right">{t("receive")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow
                key={order.identifier}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
                onClick={() => {
                  navigation("/autoshipping/detail", {
                    state: { data: order },
                  });
                }}
              >
                {console.log(order)}
                <TableCell align="left" component="th" scope="row">
                  {order.email}
                </TableCell>
                <TableCell align="right">{order.status}</TableCell>
                <TableCell align="right">
                  {new Date(order.modifiedAt).toLocaleDateString("es-MX", {
                    timeZone: "America/Mexico_City",
                  })}
                </TableCell>
                <TableCell align="right">
                  {new Date(order.interval.startedDate).toLocaleDateString(
                    "es-MX",
                    {
                      timeZone: "America/Mexico_City",
                    }
                  )}
                </TableCell>
                <TableCell align="right">{order.interval.receive}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <CircularProgress />
    );
  };

  return (
    <BasePage title={t("movements")}>
      <PaperBaseComponent>
        <AppBar
          position="static"
          color="default"
          align="center"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Toolbar>
            <Grid container spacing={0} alignItems="center">
              <Grid item>
                <SearchIcon color="inherit" sx={{ display: "block" }} />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by email address, phone number, or user UID"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: "default" },
                  }}
                  variant="standard"
                />
              </Grid>
              <Grid item>
                <Tooltip title="Reload">
                  <IconButton>
                    <RefreshIcon color="inherit" sx={{ display: "block" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {renderView()}
      </PaperBaseComponent>
    </BasePage>
  );
}
